/* eslint-disable */
import React, { useState, useEffect } from 'react';
import ColisList from '../../../ui/lists/Colis.list';
import ColisClientHeader from '../../../ui/headers/ColisClient.header';
import ConfirmModal from '../../../ui/modals/ConfirmModal';
import { clear , getClientColisHistory, selectColis, checkColis, checkAllColis  } from '../../../slices/Colis';
import { clear as clear_mu, createDispatch , create, dispatchMu } from '../../../slices/Mu';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import UserStorage from '../../../services/UserStorage';
import { routes } from '../../../utils/helpers/routing.helper';
import ColisExpedDetailsContainer from './ColisExpedDetails.container';


const MesColis = (props) => {

    const dispatch = useDispatch();
    const { loading, error, colis_list, selected_colis } = useSelector(state => state.colis);
    const { loading: loading_mu, error: error_mu } = useSelector(state => state.mu);
    const [showDetails, setShowDetails] = useState(false);
    const history = useHistory()

    const [showDelModal, setShowDelModal] = useState(false);
    const hideDelMod = () => { setShowDelModal(false) }
    const doShowDelModal = () => { setShowDelModal(true) }
    const [mod_del_title, set_mod_del_title] = useState("");
    const [mod_del_msg, set_mod_del_msg] = useState("");

    //Modal transfer confirm
    const [md_title_transfer, setMd_title_transfer] = useState("");
    const [md_msg_transfer, setMd_msg_transfer] = useState("");
    const [modalShow_transfer, setModalShow_transfer] = useState(false);
    const handleClose_transfer = () => setModalShow_transfer(false);


    const onItemDetailsClick = data => {
        dispatch(selectColis(data))
        setShowDetails(true)
    }

    const hideDetails = () => {
        setShowDetails(false)
    }

    const onItemSelected = (data, etat) => {
        dispatch(checkColis({ data, etat }));

    }
    const onSelectAll = (etat) => {
        dispatch(checkAllColis({ etat }));

    }

    const onPrint = data => {
        if (data) {
            UserStorage.setParam([data])
            const win = window.open("/dashb/colis/print/", "_blank");
            win.focus();
        }
    }

    const onPrintMany = () => {
        if (colis_list) {
            let c = colis_list.filter(elem => elem.checked == true);
            if (c.length) {
                UserStorage.setParam(c)
                const win = window.open("/dashb/colis/print/", "_blank");
                win.focus();
            }
        }
    }

    const askTransfer = () => {

        let lst_c;
        lst_c = colis_list.filter((i) => i.checked === true);

        if (colis_list) {
            if (lst_c.length) {
                let tb = <table className="table table-striped table-bordered fnt-sm">

                    <tbody>
                        <tr>
                            <td colSpan="2">Vous confirmer le transfert de la liste des colis suivante ?</td>
                        </tr>
                        {lst_c && lst_c.map((item) =>
                            <tr>
                                <td className="p-1"> <b>Colis</b> </td>
                                <td className="p-1">{item.code}</td>
                            </tr>
                        )}

                    </tbody>
                </table>;

                setShowDelModal(false)
                setMd_title_transfer("Transfert Colis")
                setMd_msg_transfer(tb);
                setModalShow_transfer(true);
            } else {
                //set_transfer_err("aucun colis selectionné !");
            }
        }
    }

    const doTransfer = () => {
        if (Array.isArray(colis_list)) {
            let sel_c = colis_list.filter(elem => elem.checked == true)
            if (sel_c.length) {
                sel_c = sel_c.map(i => (i.ref));
                let tmp_mu = {
                    colis : sel_c,
                    nbr_colis: sel_c.length
                }
                dispatch(create(tmp_mu)).unwrap().then(res_mu_create => {
                    load_data()
                    setModalShow_transfer(false);
                }).catch((err) => console.log(err));

            }
        }
    }

    const onFilter = (filter) => {
        dispatch(getClientColisHistory({filter : filter}))
    }

    const load_data = ()=>{
        dispatch(getClientColisHistory());
    }

    useEffect(() => {
       load_data()

        return () => {
            dispatch(clear());
        }
    }, [])

    return (
        <>
            <ColisClientHeader lst_data={colis_list} onPrintMany={onPrintMany} onTransfer={askTransfer} error={error} error_mu={error_mu}  onFilter={onFilter} />
            <ColisList loading={loading} error={error} lst_data={colis_list} onItemDetailsClick={onItemDetailsClick} onItemSelected={onItemSelected} onSelectAll={onSelectAll} />
            <ConfirmModal onHide={handleClose_transfer} onOk={doTransfer} title={md_title_transfer} msg={md_msg_transfer} show={modalShow_transfer} loading={loading_mu} />
            <ColisExpedDetailsContainer load_data={load_data} sel_colis={selected_colis} show={showDetails} onClose={hideDetails}   />
        </>
    )
}

export default MesColis;



