import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import MovableService from '../services/MovableService';
import UserService from '../services/UserService';
import storage from '../services/UserStorage';
import { ColisStatus } from "../utils/constants/Colis.enum";

const initialState = {
    loading: false,
    error: '',
    done: false,
    item: null,
    item_list: [],
    selected_item: null,
    search_colis : [],
    item_list_src : [],
    expeds_list  : [],
    driver_list : [] ,
};

export const listMu = createAsyncThunk(
    "mu/list",
    async (data, { rejectWithValue }) => {
        try {
            const res = await MovableService.getMuList(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const dispatchPickMu = createAsyncThunk(
    "mu/dispatchTask",
    async (data, { rejectWithValue }) => {
        try {
            const res = await MovableService.dispatchPickMu(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const pickMu = createAsyncThunk(
    "mu/pick movable",
    async (data, { rejectWithValue }) => {
        try {
            const res = await MovableService.pickMu(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)



export const getMuByCode = createAsyncThunk(
    "mu/getByCode",
    async (data, { rejectWithValue }) => {
        try {
            const res = await MovableService.getMuByCode(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const excludeMuColis = createAsyncThunk(
    "mu/cancel Mu Colis",
    async (data, { rejectWithValue }) => {
        try {
            const res = await MovableService.excludeMuColis(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)



export const refuseMu = createAsyncThunk(
    "mu/del",
    async (data, { rejectWithValue }) => {
        try {
            const res = await MovableService.refuseMu(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)




export const loadExpedsList = createAsyncThunk(
    "mu/load expeds",
    async (data, { rejectWithValue }) => {
        try {
            const res = await UserService.listAdmExped()
            console.log(res)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const loadDrivers = createAsyncThunk(
    "mu/load driver",
    async (data, { rejectWithValue }) => {
        try {
            const res = await UserService.listAdmDriver()
            console.log(res)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const createMuTransfert = createAsyncThunk(
    "mu/createMuTransfert",
    async (data, { rejectWithValue }) => {
        try {
            const res = await MovableService.createMuTransfert(data)
            console.log(res)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const dispatchMuTransfert = createAsyncThunk(
    "mu/dispatchMuTransfert",
    async (data, { rejectWithValue }) => {
        try {
            const res = await MovableService.dispatchMuTransfert(data)
            console.log(res)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const deleteMuTransfert = createAsyncThunk(
    "mu/deleteMuTransfert",
    async (data, { rejectWithValue }) => {
        try {
            const res = await MovableService.deleteMuTransfert(data)
            console.log(res)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const pickMuTransfert = createAsyncThunk(
    "mu/pickMuTransfert",
    async (data, { rejectWithValue }) => {
        try {
            const res = await MovableService.pickMuTransfert(data)
            console.log(res)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const listMuTransfert = createAsyncThunk(
    "mu/listMuTransfert",
    async (data, { rejectWithValue }) => {
        try {
            const res = await MovableService.listMuTransfert(data)
            console.log(res)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)



//to fix
export const getClientHistory = createAsyncThunk(
    "mu/history",
    async (data, { rejectWithValue }) => {
        try {
            const res = await MovableService.getClientMuHistory()
            console.log(res)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)



const muSlice = createSlice({
    name: "mu",
    initialState,
    reducers: {
        clear: (state, action) => {
            state.loading = false;
            state.error = '';
            state.item = null;
            state.done = false;
            state.item_list = [];
            state.item_list_src = [];
            state.selected_item = null ;
            state.search_colis = [];
            state.expeds_list = [] ;
            state.driver_list = [] ;
            
        },

        checkColis: (state, action) => {
            console.log(action)
            if (action.payload) {
                let lst =
                    state.item.colis.map(item =>
                        item.ref === action.payload.ref ? { ...item, checked: action.payload.etat } : item
                    )
                state.item = {...state.item , colis : lst };
                // state.item.colis = lst;
            }
        },
        scanCheckColis: (state, action) => {
            console.log(action)
            if (action.payload) {
                let lst =
                    state.item.colis.map(item =>
                        item.code === action.payload.code ? { ...item, checked: action.payload.etat } : item
                    )
                state.item = {...state.item , colis : lst };
                // state.item.colis = lst;
            }
        },
        checkAllColis: (state, action) => {
            console.log(action)
            if (action.payload) {
                let lst =
                    state.item.colis.map(item => ({ ...item, checked: action.payload.etat }) )
                    state.item = {...state.item , colis : lst };
                // state.item.colis = lst;
            }
        },
        doSelect: (state, action) => {
            state.selected_item = action.payload;
        },
        doFilter : (state , action) => {
            console.log(action.payload)
            if(action.payload.id != "")
                state.item_list = state.item_list_src.filter(elem => elem.mvExped && elem.mvExped.id  == action.payload.id )
            else
                state.item_list = state.item_list_src ;
        }
    },
    extraReducers: {
       
        [listMu.fulfilled]: (state, action) => {
            if (action.payload) {
                state.item_list = action.payload;
                state.item_list_src = action.payload;
            }
            state.error = '';
            state.loading = false;

        },
        [listMu.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [listMu.pending]: (state, action) => {
            state.loading = true;
            state.item_list = [];
            state.item_list_src = [];
        },
        [pickMu.fulfilled]: (state, action) => {
            if (action.payload) {
                if (action.payload.done) {
                    state.done = true;
                    state.item = action.payload.mu;
                }
            }
            state.error = '';
            state.loading = false;

        },
        [pickMu.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [pickMu.pending]: (state, action) => {
            state.loading = true;
            state.done = false ;
        },
        [refuseMu.fulfilled]: (state, action) => {
            if (action.payload) {
                if (action.payload.done) {
                    state.done = true;
                    
                }
            }
            state.error = '';
            state.loading = false;

        },
        [refuseMu.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [refuseMu.pending]: (state, action) => {
            state.loading = true;
            state.done = false ;
        },
       
        [getMuByCode.fulfilled]: (state, action) => {
            if (action.payload) {
                state.item = action.payload;
                if(Array.isArray(state?.item?.colis)){
                    state.item = {...state.item, colis : state?.item?.colis.map(el => el?.etat == ColisStatus["ENLEV-SUCC"] ? {...el, checked : true} : el)};
                }
            }
            state.error = '';
            state.loading = false;

        },
        [getMuByCode.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [getMuByCode.pending]: (state, action) => {
            state.loading = true;
            state.item = null;
        },
        [dispatchPickMu.fulfilled]: (state, action) => {
            if (action.payload) {
                if (action.payload.done) {
                    state.done = true;
                    
                }
            }
            state.error = '';
            state.loading = false;

        },
        [dispatchPickMu.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [dispatchPickMu.pending]: (state, action) => {
            state.loading = true;
            state.done = false ;
        },
        [excludeMuColis.fulfilled]: (state, action) => {
            if (action.payload) {
                if (action.payload.done) {
                    state.done = true;
                    
                }
            }
            state.error = '';
            state.loading = false;

        },
        [excludeMuColis.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [excludeMuColis.pending]: (state, action) => {
            state.loading = true;
            state.done = false ;
        },
        [loadExpedsList.fulfilled]: (state, action) => {
            if (action.payload) {
                state.expeds_list = action.payload;
            }
            state.error = '';
            state.loading = false;

        },
        [loadExpedsList.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [loadExpedsList.pending]: (state, action) => {
            state.loading = true;
            state.expeds_list = [];
        },

        [loadDrivers.fulfilled]: (state, action) => {
            if (action.payload) {
                state.driver_list = action.payload;
            }
           // state.error = '';
            state.loading = false;

        },
        [loadDrivers.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [loadDrivers.pending]: (state, action) => {
            state.loading = true;
            state.driver_list = [];
        },




        [createMuTransfert.fulfilled]: (state, action) => {
            state.error = '';
            state.loading = false;

        },
        [createMuTransfert.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [createMuTransfert.pending]: (state, action) => {
            state.loading = true;
            state.error = '';
        },


        [dispatchMuTransfert.fulfilled]: (state, action) => {
            state.error = '';
            state.loading = false;

        },
        [dispatchMuTransfert.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [dispatchMuTransfert.pending]: (state, action) => {
            state.loading = true;
            state.error = '';
        },


        [deleteMuTransfert.fulfilled]: (state, action) => {
            state.error = '';
            state.loading = false;

        },
        [deleteMuTransfert.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [deleteMuTransfert.pending]: (state, action) => {
            state.loading = true;
            state.error = '';
        },


        [pickMuTransfert.fulfilled]: (state, action) => {
            state.error = '';
            state.loading = false;

        },
        [pickMuTransfert.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [pickMuTransfert.pending]: (state, action) => {
            state.loading = true;
            state.error = '';
        },



        [listMuTransfert.fulfilled]: (state, action) => {
            state.error = '';
            state.loading = false;

        },
        [listMuTransfert.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [listMuTransfert.pending]: (state, action) => {
            state.loading = true;
            state.error = '';
        },


        
    }
})

const { reducer } = muSlice;
export const { clear, checkColis , doSelect , checkAllColis , doFilter , scanCheckColis } = muSlice.actions;
export default reducer;