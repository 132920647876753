/* eslint-disable */
import React, { useState, useEffect } from 'react';
import RunsheetList from '../../../ui/lists/Runsheet.list';
import RunsheetHeader from '../../../ui/headers/Runsheet.header';
import RunsheetDetails from '../../../ui/layouts/RunsheetDetails';
import ConfirmModal from '../../../ui/modals/ConfirmModal';

import { clear, getRsList, deleteRs, dispatchRs, doSelect, loadDrivers } from '../../../slices/RsAdm';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import UserStorage from '../../../services/UserStorage';
import { routes } from '../../../utils/helpers/routing.helper';
import { stat as rsStat } from '../../../utils/constants/Runsheet.enum';


const Runsheet = () => {

    const dispatch = useDispatch();
    const {current_hub } = useSelector((state) => state.hub);
    const { loading, error, item_list, selected_item, driver_list } = useSelector(state => state.rs_adm);
    const [showDetails, setShowDetails] = useState(false);
    const history = useHistory()

    const [showDelModal, setShowDelModal] = useState(false);
    const hideDelMod = () => { setShowDelModal(false) }
    const doShowDelModal = () => { setShowDelModal(true) }
    const [mod_del_title, set_mod_del_title] = useState("");
    const [mod_del_msg, set_mod_del_msg] = useState("");

    const [showDispModal, setShowDispModal] = useState(false);
    const hideDispMod = () => { setShowDispModal(false) }
    const doShowDispModal = () => { setShowDispModal(true) }
    const [mod_disp_title, set_mod_disp_title] = useState("");
    const [mod_disp_msg, set_mod_disp_msg] = useState("");



    const load_rs = (filter) => {
            dispatch(getRsList({filter : filter}));
    }


    const onItemDetailsClick = data => {
        dispatch(doSelect(data))
        setShowDetails(true)
    }

    const hideDetails = () => {
        setShowDetails(false)
    }


    const onPrint = (data) => {
        if (data) {
            dispatch(doSelect(data));
            UserStorage.setParam(data)
            const win = window.open(routes.rs_adm_print, "_blank");
            win.focus();
        }
    }

    const onEdit = () => {
        if (selected_item)
            history.push(routes.rs_adm_edit + selected_item.ref);
    }

    const onDelete = () => {
        if (selected_item) {
            set_mod_del_title("Supprimer runsheet")
            set_mod_del_msg("Voulez-vous supprimer runsheet " + selected_item.ref + " ?")
            setShowDelModal(true);

        }
    }

    const doDelete = () => {
        if (selected_item) {
            console.log(selected_item)
            dispatch(deleteRs({id : selected_item.id})).unwrap().then(() => {
                setShowDetails(false)
                setShowDelModal(false);
                load_rs()
            })
        }
    }

    const askDispatch = (data) => {
        if (data) {
            dispatch(doSelect(data));
            set_mod_disp_title("Dispatch runsheet")
            set_mod_disp_msg("Voulez-vous dispatcher runsheet " + data.ref + " ?")
            doShowDispModal()
        }
    }

    const onDispatch = () => {
        if (selected_item) {
            dispatch(dispatchRs(selected_item.id )).unwrap().then(d => {
                hideDispMod()
                setShowDetails(false)
                load_rs()
            })
        }

    }





    useEffect(() => {

        dispatch(loadDrivers());

        return () => {
            dispatch(clear());
        }
    }, [])

    useEffect(() => {
        load_rs() 
    }, [current_hub])

    return (
        <>
            <RunsheetHeader lst_data={item_list} loading={loading} error={error} driver_list={driver_list} load_data={load_rs} />
            <RunsheetList loading={loading} error={error} lst_data={item_list} onDetails={onItemDetailsClick} onDispatch={askDispatch} onPrint={onPrint}  />
            <RunsheetDetails data={selected_item} isShow={showDetails} onHide={hideDetails} onPrint={onPrint} onEdit={onEdit} onDelete={onDelete} />
            <ConfirmModal onHide={hideDispMod} onOk={onDispatch} title={mod_disp_title} msg={mod_disp_msg} show={showDispModal} loading={loading} />
            <ConfirmModal onHide={hideDelMod} onOk={doDelete} title={mod_del_title} msg={mod_del_msg} show={showDelModal} loading={loading} />


        </>
    )
}

export default Runsheet;



