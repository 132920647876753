/* eslint-disable */
import React, { useState, useEffect } from "react";
import ColisRsFormList from "../../../ui/lists/ColisRsForm.list";
import RunsheetFormHeader from "../../../ui/headers/RunsheetForm.header";
import DriverPickModal from "../../../ui/modals/DriverPickModal";
import DriverPickEditModal from "../../../ui/modals/DriverPickEditModal";
import ColisAdmDetails from "../../../ui/layouts/ColisAdmDetails";
import { clear, createRs, loadDriversDispo, editRs, getRsByCode } from "../../../slices/RsAdm";
import { clear as clear_colis, getOneColisByFilter, listColisAdm, selectColis, editColisInfo, setError } from "../../../slices/ColisAdm";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import UserStorage from "../../../services/UserStorage";
import { routes } from "../../../utils/helpers/routing.helper";
import { ColisStatus } from "../../../utils/constants/Colis.enum";
import BarcodeReader from "react-barcode-reader";
import ColisAdmDetailsContainer from "../package/ColisAdmDetails.container";

const RunsheetCreate = ({ isEdit, match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { current_hub } = useSelector((state) => state.hub);
  const { loading, error, item, driver_list } = useSelector((state) => state.rs_adm);
  const { loading: loading_colis, error: error_colis, selected_colis } = useSelector((state) => state.colis_adm);
  const [code_scan, set_code_scan] = useState("");
  const [sel_liv, set_sel_liv] = useState(0);
  const [lst_colis, set_lst_colis] = useState([]);
  const [lst_colis_src, set_lst_colis_src] = useState([]);
  const id = match.params.code;

  //driver modal
  const [showDrivModal, setShowDrivModal] = useState(false);
  const hideDrivMod = () => {
    setShowDrivModal(false);
  };
  const ShowDrivModal = () => {
    setShowDrivModal(true);
  };
  //colis dtails
  const [showDetails, setShowDetails] = useState(false);
  const hideDetails = () => {
    setShowDetails(false);
  };

  const onSelectItem = (data) => {
    dispatch(selectColis(data));
    setShowDetails(true);
  };

  const onSearch = (search, callback) => {
    let locals = searchFromSrc(search);
    console.log(locals);
    dispatch(listColisAdm({ filter: { search: search, etat: [ColisStatus["ATT-LIV"] , ColisStatus["ATT-RECUP"]] } }))
      .unwrap()
      .then((data) => {
        if (Array.isArray(data) && data.length > 0) callback(data.map((i) => ({ label: `${i.code}.(${i.nom_cl} )`, value: i })));
        else if (locals) callback(locals.map((i) => ({ label: `${i.code}.(${i.nom_cl} )`, value: i })));
        else callback([]);
      });
  };

  const searchFromSrc = (s) => {
    console.log("search from src ");
    if (Array.isArray(lst_colis_src)) return lst_colis_src.filter((el) => String(el?.code).includes(s));
    else return [];
    // let rslt = [];
    // lst_colis.forEach((elem) => {
    //   if (String(elem.code).includes(s)) {
    //     rslt = [...rslt, elem];
    //   }
    // });
    // return rslt;
  };

  const handleScan = (data) => {
    console.log(data);
    set_code_scan(data);
    if (isNaN(data)) {
      console.log(data + " is not  number ! ");
      dispatch(setError("code colis erroné , tapez sur MAJ et essayer svp !"));
    } else {
      console.log(data + " is number ");
      if (data) {
        if (verifColisExist(data)) dispatch(setError("Colis:" + data + " existe déja dans la liste !"));
        else
          dispatch(getOneColisByFilter({ code: data, etat: [ColisStatus["ATT-LIV"] , ColisStatus["ATT-RECUP"]] }))
            .unwrap()
            .then((colis) => {
              console.log(colis);
              if (colis) set_lst_colis([colis, ...lst_colis]);
              else dispatch(setError("Aucun Colis trouvé avec le réf :" + data + " !"));
            });
      }
    }
  };

  const verifColisExist = (code) => {
    let found = false;
    lst_colis.forEach((i) => {
      if (i.code === code) found = true;
    });
    return found;
  };

  const handleError = (err) => {
    console.log(err);
  };

  const onSubmit = () => {
    if (!lst_colis || (Array.isArray(lst_colis) && lst_colis.length == 0)) {
      dispatch(setError("Vous devez ajouter des colis pour ce runsheet !"));
    } else {
      ShowDrivModal();
    }
  };

  const onCreate = () => {
    if (!lst_colis || (Array.isArray(lst_colis) && lst_colis.length == 0)) {
      dispatch(setError("Vous devez ajouter des colis pour ce runsheet !"));
    } else if (!sel_liv || sel_liv == 0) {
      dispatch(setError("Vous devez choisir un livreur !"));
    } else {
      let colis = [];
      let mnt = 0.0;
      lst_colis.forEach((elem) => {
        colis = [...colis, elem.ref];
        mnt = +mnt + +elem.montant_ttc;
      });

      dispatch(
        createRs({
          colis: colis,
          montant_tot: mnt,
          id_liv: sel_liv,
          nbr_colis: lst_colis.length,
        })
      )
        .unwrap()
        .then((rslt) => {
          history.push(routes.rs_adm_pd);
        });
    }
  };

  const onEdit = () => {
    if (item) {
      if (!lst_colis || (Array.isArray(lst_colis) && lst_colis.length == 0)) {
        dispatch(setError("Vous devez ajouter des colis pour ce runsheet !"));
      } else if (!sel_liv || sel_liv == 0) {
        dispatch(setError("Vous devez choisir un livreur !"));
      } else {
        let colis = [];
        let mnt = 0.0;
        lst_colis.forEach((elem) => {
          colis = [...colis, elem.ref];
          mnt = +mnt + +elem.montant_ttc;
        });

        dispatch(
          editRs({
            id: item.id,
            colis: colis,
            montant_tot: mnt,
            id_liv: sel_liv,
            nbr_colis: lst_colis.length,
          })
        )
          .unwrap()
          .then((rslt) => {
            history.push(routes.rs_adm_pd);
          });
      }
    }
  };

  const onRemoveColis = (indx) => {
    let array = [...lst_colis];

    if (indx !== -1) {
      array.splice(indx, 1);
      set_lst_colis(array);
    }
  };

  const onPrint = (data) => {
    if (data) {
      UserStorage.setParam([data]);
      const win = window.open("/dashb/colis/print/", "_blank");
      win.focus();
    }
  };

  const onEditField = (field, value) => {
    dispatch(editColisInfo({ id: selected_colis.ref, field: field, f_data: value }))
      .unwrap()
      .then((d) => {
        setShowDetails(false);
        window.location.reload(false);
      });
  };

  useEffect(() => {
    dispatch(loadDriversDispo());
    if (id && isEdit) {
      dispatch(getRsByCode(id));
    }

    return () => {
      dispatch(clear());
      dispatch(clear_colis());
    };
  }, []);

  useEffect(() => {
    if (isEdit && item) {
      if (Array.isArray(item.colis)) {
        set_lst_colis(item.colis);
        set_lst_colis_src(item.colis);
      }
      if (item.rsLiv) set_sel_liv(item.rsLiv.id);
    }
  }, [item]);

  useEffect(() => {
    set_lst_colis([]);
  }, [current_hub]);

  return (
    <>
      <>
        <BarcodeReader onError={handleError} onScan={handleScan} />
        <RunsheetFormHeader
          loading={loading}
          error={error}
          error_colis={error_colis}
          onSubmit={onSubmit}
          onSearch={onSearch}
          lst_data={lst_colis}
          set_lst_data={set_lst_colis}
          isEdit={isEdit}
          rs={item}
        />
        <ColisRsFormList loading={loading} error={error} lst_data={lst_colis} onItemSelected={onSelectItem} onRemove={onRemoveColis} />
        {/* <ColisAdmDetails data={selected_colis} isShow={showDetails} onHide={hideDetails} onPrint={onPrint} onEditField={onEditField} loading={loading} /> */}
        <ColisAdmDetailsContainer show={showDetails} onClose={hideDetails}  sel_colis={selected_colis} load_data={()=>{}} />
        {isEdit && item ? (
          <DriverPickEditModal onHide={hideDrivMod} onOk={isEdit ? onEdit : onCreate} show={showDrivModal} loading={loading} onSelectDriver={set_sel_liv} drivers={driver_list} rsLiv={item.rsLiv} />
        ) : (
          <DriverPickModal onHide={hideDrivMod} onOk={isEdit ? onEdit : onCreate} show={showDrivModal} loading={loading} onSelectDriver={set_sel_liv} drivers={driver_list} />
        )}
      </>
    </>
  );
};

export default RunsheetCreate;
