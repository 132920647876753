import axios from "axios";
import { api_url } from './http_constant';
import  storage from './UserStorage';

class TicketService {

    //Exped Services----------------------------------------------------------------------------------------------------
    //----------------------------------------------------------------------------------------------------------------
    createExpedTicket(ticket) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/ticket/exped/create`, ticket, {
            headers: {
                'cgo-tkn': token
            }
        });
    }
    cancelExpedTicket(id) {
        let token = storage.getToken();
        if(id)
        return axios.get(`${api_url}/api/ticket/exped/cancel/${id}`, {
            headers: {
                'cgo-tkn': token
            }
        });
    }
    listExpedTicket(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/ticket/exped/list`,data, {
            headers: {
                'cgo-tkn': token
            }
        });
    }


    //Admin Services----------------------------------------------------------------------------------------------------
    //----------------------------------------------------------------------------------------------------------------

    listAdmTicket(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/ticket/adm/list`,data, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    applyAdmTicket(id) {
        let token = storage.getToken();
        if(id)
        return axios.get(`${api_url}/api/ticket/adm/apply/${id}`, {
            headers: {
                'cgo-tkn': token
            }
        });
    }


    refuseAdmTicket(id) {
        let token = storage.getToken();
        if(id)
        return axios.get(`${api_url}/api/ticket/adm/refuse/${id}`, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    cancelAdmTicket(id) {
        let token = storage.getToken();
        if(id)
        return axios.get(`${api_url}/api/ticket/adm/cancel/${id}`, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

}

export default new TicketService();