import React, { useContext, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getStringDate } from "../../utils/helpers/date.helper";
import { ColisAfexStatusBg, ColisAfexStatusLabels, ColisStatus, ColisStatusBg, colisStatusLabels, ColisTypeEnvoie, ColisTypeEnvoieLabels } from "../../utils/constants/Colis.enum";
import UserStorage from "../../services/UserStorage";
import ColisEditInfoModal from "../modals/ColisEditInfoModal";

const ColisAdmDetails = ({ data, isShow, onHide, onDelete, onPrint, onEditField, loading }) => {
  const [colis_com, set_colis_com] = useState([]);
  const [colis_history, set_colis_history] = useState([]);
  const [selTab, setSelTab] = useState(0);
  const [user, setUser] = useState(UserStorage.getData());

  const [field, set_field] = useState();
  const [f_data, set_f_data] = useState();

  const [show_edit_mod, set_show_edit_mod] = useState(false);
  const hideEditMod = () => {
    set_show_edit_mod(false);
  };

  const onEdit = (fild, value) => {
    set_field(fild);
    set_f_data(value);
    set_show_edit_mod(true);
  };

  useEffect(() => {
    if (isShow && data) {
      setSelTab(0)
      console.log(data);
      let com = data.coliscoms;
      let com_clean = [];
      com.map((x) =>
        com_clean.filter((a) => a.name == x.name && a.createdAt == x.createdAt && a.type == x.type && a.duration == x.duration && a.content == x.content).length > 0 ? null : com_clean.push(x)
      );
      set_colis_com(com_clean);

      if (Array.isArray(data?.colishistories)) {
        let tmp = data?.colishistories.toSorted((a, b) => +b?.id - +a?.id);
        console.log(tmp);
        set_colis_history(tmp);
      }
    }
  }, [data, isShow]);

  useEffect(() => {
    if (show_edit_mod && !isShow) {
      hideEditMod();
    }
  }, [isShow]);

  return (
    <>
      <div className={isShow ? "col-md-6 bg-gray inf_box_pop_r" : "col-md-6 bg-gray inf_box_pop_r inf_box_pop_r_off"}>
        <div className="row no-padd no-marg bg-gray">
          <button className="btn_close" onClick={onHide}>
            <FontAwesomeIcon icon={["fas", "chevron-right"]} />
          </button>
        </div>

        <div className="row no-marg bg-wt">
          <div className="col-md-12 no-padd" id="tabs">
            <nav className="bg-gray">
              <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                <button
                  className={selTab == 0 ? "nav-item nav-link active" : "nav-item nav-link"}
                  id="nav-home-tab"
                  data-toggle="tab"
                  role="tab"
                  onClick={() => {
                    setSelTab(0);
                  }}
                >
                  General
                </button>
                {/* <button
                  className={selTab == 1 ? "nav-item nav-link active" : "nav-item nav-link"}
                  id="nav-profile-tab"
                  data-toggle="tab"
                  role="tab"
                  onClick={() => {
                    setSelTab(1);
                  }}
                >
                  Article(s)
                </button> */}
                <button
                  className={selTab == 1 ? "nav-item nav-link active" : "nav-item nav-link"}
                  id="nav-contact-tab"
                  data-toggle="tab"
                  role="tab"
                  onClick={() => {
                    setSelTab(1);
                  }}
                >
                  Communication
                </button>
                <button
                  className={selTab == 2 ? "nav-item nav-link active" : "nav-item nav-link"}
                  id="nav-about-tab"
                  data-toggle="tab"
                  role="tab"
                  onClick={() => {
                    setSelTab(2);
                  }}
                >
                  Historique
                </button>
              </div>
            </nav>
            <div className="tab-content col-md-12 p-3" id="nav-tabContent">
              <div className={selTab == 0 ? "tab-pane fade show active col-md-12" : "tab-pane fade col-md-12"} id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                {data ? (
                  <div className="row">
                    <div className="col-md-12 border-bl-rnd    mb-3">
                      <div className="row no-marg">
                        <div className="col-md-6 txt-ctr dvdr-r-gray p-2">
                          <b className="item_box_ref">
                            <span className="spn_inf_tit dsp_blk fnt-sm">Colis</span>
                            <span>
                              <FontAwesomeIcon icon={["fas", "barcode"]} />{" "}
                            </span>
                            <span className="fnt-w5 txt_blk">{data.code}</span>
                          </b>
                        </div>
                        <div className="col-md-6 txt-ctr p-2">
                          <b>
                            <span className="spn_inf_tit dsp_blk fnt-sm fnt-w5">Etat</span>
                            {data?.is_afex ? 
                            <span class={`badge badge-pill  ${ColisAfexStatusBg[data?.etat_afex]}`}>{ColisAfexStatusLabels[data?.etat_afex]} </span>
                            :
                            <>
                            {String(data?.etat).includes("LIV-") && data?.type_envoi == ColisTypeEnvoie.recuperation ? (
                              <span class={`badge badge-pill  ${ColisStatusBg[data?.etat]}`}>{String(colisStatusLabels[data?.etat]).replace("Livraison", "Récupération")} </span>
                            ) : (
                              <span class={`badge badge-pill  ${ColisStatusBg[data?.etat]}`}>{colisStatusLabels[data?.etat]} </span>
                            )}
                            </>
                            }
                          </b>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <b className="fnt-w5">
                        <span className="spn_inf_tit">Expediteur </span>
                        <span>{data.user && data.user.nom_com}</span>
                      </b>
                    </div>
                    <div className="col-md-12">
                      <b className="fnt-w5">
                        <span className="spn_inf_tit">Client </span>
                        <span>
                          {data.nom_cl} 
                        </span>
                      </b>
                    </div>
                    <div className="col-md-12">
                      <b className="fnt-w5">
                        <span className="spn_inf_tit">Tél Client </span>
                        <span>{data.tel_cl}</span>
                      </b>
                    </div>

                    <div className="col-md-12">
                      <b className="fnt-w5">
                        <span className="spn_inf_tit">Adresse </span>
                        <span>{data.adresse}</span>
                      </b>
                    </div>
                    <div className="col-md-12">
                      <b className="fnt-w5">
                        <span className="spn_inf_tit">Lieu </span>
                        <span>{data.adresse_lieu}</span>
                      </b>
                    </div>

                    <div className="col-md-12">
                      <b className="fnt-w5">
                        <span className="spn_inf_tit">Montant TTC</span>
                        <b className="item_box_money">
                          {data.montant_ttc} <span>TND</span>{" "}
                        </b>
                      </b>
                    </div>
                   
                    <div className="col-md-12">
                      <b className="fnt-w5">
                        <span className="spn_inf_tit">Type d'envoi</span>
                        <b className="item_box_money">
                          {ColisTypeEnvoieLabels[data.type_envoi]}
                        </b>
                      </b>
                    </div>
                    <div className="col-md-12">
                      <b className="fnt-w5">
                        <span className="spn_inf_tit">Poids Supp</span>
                        <b className="item_box_money">
                          {data.poids_supp} <span>KG</span>{" "}
                        </b>
                      </b>
                    </div>
                    <div className="col-md-12">
                      <b className="fnt-w5">
                        <span className="spn_inf_tit">Marchandise </span>
                        <span>{data.marchandise}</span>
                      </b>
                    </div>

                    <div className="col-md-12">
                      <b className="fnt-w5">
                        <span className="spn_inf_tit">Nombre de paquets</span>
                        <b className="item_box_money">
                          {data.montant_remboursement} <span>TND</span>{" "}
                        </b>
                      </b>
                    </div>

                    <div className="col-md-12">
                      <b className="fnt-w5">
                        <span className="spn_inf_tit">Date création </span>
                        <span>{getStringDate(data.createdAt)}</span>
                      </b>
                    </div>
                    <div className="col-md-12">
                      <b className="fnt-w5">
                        <span className="spn_inf_tit">Gérer par </span>
                        <span>{data.is_afex ? 'Afex':'Delivo'}</span>
                      </b>
                    </div>
                    <div className="col-md-12">
                      <b className="fnt-w5">
                        <span className="spn_inf_tit">Tentatives </span>
                        <span>{data.attempt}/3</span>
                      </b>
                    </div>

                    <div className="col-md-12">
                      <b className="fnt-w5">
                        <span className="spn_inf_tit">Note Client</span>
                        <span>{data.note}</span>
                      </b>
                    </div>

                    <div className="col-md-12">
                      <b className="fnt-w5">
                        <span className="spn_inf_tit">Note Afex</span>
                        <span>{data.note_afex}</span>
                      </b>
                    </div>

                    <div className="col-md-12">
                      <b className="fnt-w5">
                        <span className="spn_inf_tit">Date reporté </span>
                        <span>{data.date_programe ? data.date_programe : "_"}</span>
                      </b>
                    </div>

                    <div className="row m-0 my-2 p-1" style={{ border: "#00000063 1px solid", borderRadius: 3 }}>
                      <div className="col-md-4">
                        <b className="fnt-w5">
                          <span className="spn_inf_tit">Hub d'éxpedition </span>
                          <span>{data?.colis_hub_exped?.nom}</span>
                        </b>
                      </div>

                      <div className="col-md-4 brd-l-1">
                        <b className="fnt-w5">
                          <span className="spn_inf_tit">Hub déstination </span>
                          <span>{data?.colis_hub_dest?.nom}</span>
                        </b>
                      </div>
                      <div className="col-md-4 brd-l-1">
                        <b className="fnt-w5">
                          <span className="spn_inf_tit">Hub actuel </span>
                          <span>{data?.colis_hub_current?.nom}</span>
                        </b>
                      </div>
                    </div>

                    <div className="col-md-12 pb-4 pt-2 mt-2 dvdr-t-gray ">
                      <button className="btn btn-outline-success btn-sm" onClick={(e) => onPrint(data)}>
                        <FontAwesomeIcon icon={["fas", "print"]} /> Imprimer bordereau d'expédition
                      </button>

                      {user && user.role == "root" && (
                        <>
                          <button className="btn btn-outline-success btn-sm ms-1" onClick={() => onEdit("etat", data.etat)}>
                            Edit status
                          </button>

                          {/* <button className="btn btn-outline-success btn-sm ms-1" onClick={() => onEdit("zone_exped", data.zone_exped)}>
                            <FontAwesomeIcon icon={["fas", "edit"]} /> Modifier zone d'expedition
                          </button>
                          <button className="btn btn-outline-success btn-sm  mt-1 " onClick={() => onEdit("zone_dest", data.zone_dest)}>
                            <FontAwesomeIcon icon={["fas", "edit"]} /> Modifier zone du destination
                          </button>
                          <button className="btn btn-outline-success btn-sm  ms-1 mt-1" onClick={() => onEdit("zone_current", data.zone_current)}>
                            <FontAwesomeIcon icon={["fas", "edit"]} /> Modifier zone courrante{" "}
                          </button> */}

                          <button className="btn btn-outline-success btn-sm  ms-1 mt-1" onClick={() => onEdit("montant", data.montant_ttc)}>
                            <FontAwesomeIcon icon={["fas", "edit"]} /> Modifier Montant{" "}
                          </button>
                        </>
                      )}
                      <button className="btn btn-outline-success btn-sm  mt-1 ms-1" onClick={() => onEdit("tel", data.tel_cl)}>
                        <FontAwesomeIcon icon={["fas", "edit"]} /> Modifier Tél{" "}
                      </button>
                      <button className="btn btn-outline-success btn-sm  mt-1 ms-1 " onClick={() => onEdit("adresse", data.adresse)}>
                        <FontAwesomeIcon icon={["fas", "edit"]} /> Modifier Adresse{" "}
                      </button>
                      <button className="btn btn-outline-success btn-sm  mt-1 ms-1 " onClick={() => onEdit("poids_supp", data.adresse)}>
                        <FontAwesomeIcon icon={["fas", "edit"]} /> Modifier Poids Supp{" "}
                      </button>
                    </div>
                  </div>
                ) : (
                  <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                )}
              </div>

              {/* <div className={selTab == 1 ? "tab-pane fade show active" : "tab-pane fade"} id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                <br />
                <table className="table table-striped table-bordered fnt-sm">
                  <thead>
                    <tr>
                      <th scope="col">Article</th>
                      <th scope="col">Quantité</th>
                      <th scope="col">Prix Unitaire HT</th>
                      <th scope="col">Prix HT</th>
                      <th scope="col">Prix TTC</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.articles &&
                      data.articles.map((item) => (
                        <tr>
                          <td className="p-1">{item.libelle}</td>
                          <td className="p-1">{item.nbr}</td>
                          <td className="p-1">{item.prix_unit}</td>
                          <td className="p-1">{item.prix_ht}</td>
                          <td className="p-1">{item.prix_ttc}</td>
                        </tr>
                      ))}

                    <tr className="item_box_money">
                      <th colspan="3" scope="row">
                        Total
                      </th>
                      <td className="fnt-w5">{data && data.montant_ht}</td>
                      <td className="fnt-w5">{data && data.montant_ttc}</td>
                    </tr>
                  </tbody>
                </table>
              </div> */}

              <div className={selTab == 1 ? "tab-pane fade show active" : "tab-pane fade"} id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                <br />
                <table className="table table-striped table-bordered fnt-sm">
                  <thead>
                    <tr>
                      <th scope="col">Nom</th>
                      <th scope="col">Type</th>
                      <th scope="col">Contenu</th>
                      <th scope="col">Durée d'appel</th>
                      <th scope="col">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.coliscoms &&
                      colis_com.map((item) => (
                        <tr>
                          <td className="p-1">{item.name}</td>
                          <td className="p-1">{item.type}</td>
                          <td className="p-1">{item.content}</td>
                          <td className="p-1">{item.duration}</td>
                          <td className="p-1">{item.createdAt}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>

              <div className={selTab == 2 ? "tab-pane fade show active" : "tab-pane fade"} id="nav-about" role="tabpanel" aria-labelledby="nav-about-tab">
                <br />
                <table className="table table-striped table-bordered fnt-sm">
                  <thead>
                    <tr>
                      <th scope="col">Tache</th>
                      <th scope="col">Date</th>
                      <th scope="col">Fait par</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(colis_history) &&
                      colis_history.map((item) => (
                        <tr>
                          <td className="p-1">{item.task}</td>
                          <td className="p-1">{getStringDate(item.createdAt)}</td>
                          <td className="p-1">
                            <b>{item.doneby}</b>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ColisEditInfoModal onSubmit={onEditField} show={show_edit_mod} loading={loading} onHide={hideEditMod} field={field} f_data={f_data} set_f_data={set_f_data} colis={data} />
    </>
  );
};

export default ColisAdmDetails;
