import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ColisService from '../services/ColisService';
import UserService from '../services/UserService';
import storage from '../services/UserStorage';

const initialState = {
    loading: false,
    error: '',
    done: false,
    data: null,
    colis_list: [],
    colis_list_src : [] ,
    selected_colis: null,
    lst_sel_colis: [],
    expeds_list  : [],

};

export const listColisAdm = createAsyncThunk(
    "colis/list",
    async (data, { rejectWithValue }) => {
        try {
            const res = await ColisService.getAdmColisList(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const getOneColisByFilter = createAsyncThunk(
    "colis/getOneColisByFilter",
    async (data, { rejectWithValue }) => {
        try {
            const res = await ColisService.getOneColisByFilter(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const editColisInfo = createAsyncThunk(
    "colis/edit colis info",
    async (data, { rejectWithValue }) => {
        try {
            const res = await ColisService.editColisInfo(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const del = createAsyncThunk(
    "colis/del",
    async (data, { rejectWithValue }) => {
        try {
            const res = await ColisService.deleteColis(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

// export const searchStatColis = createAsyncThunk(
//     "colis/search",
//     async (data, { rejectWithValue }) => {
//         try {
//             const res = await ColisService.searchAdmColisStatList(data)
//             return res.data;
//         } catch (error) {
//             const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
//             return rejectWithValue(message)
//         }
//     }
// )
// export const searchAllColis = createAsyncThunk(
//     "colis/searchAll",
//     async (data, { rejectWithValue }) => {
//         try {
//             const res = await ColisService.searchAdmAllColis(data)
//             return res.data;
//         } catch (error) {
//             const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
//             return rejectWithValue(message)
//         }
//     }
// )

export const getClientColisHistory = createAsyncThunk(
    "colis/history",
    async (data, { rejectWithValue }) => {
        try {
            const res = await ColisService.getClientColisHistory()
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const loadExpedsList = createAsyncThunk(
    "mu/load expeds",
    async (data, { rejectWithValue }) => {
        try {
            const res = await UserService.listAdmExped()
            console.log(res)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const getColisByCodeAdm = createAsyncThunk(
    "colis/getColisByCodeAdm",
    async (data, { rejectWithValue }) => {
        try {
            const res = await ColisService.getColisByCodeAdm(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)
export const searchAdmGlobalColis = createAsyncThunk(
    "colis/searchAdmGlobalColis",
    async (data, { rejectWithValue }) => {
        try {
            const res = await ColisService.searchAdmGlobal(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)


const colisSlice = createSlice({
    name: "colis",
    initialState,
    reducers: {
        clear: (state, action) => {
            state.loading = false;
            state.error = '';
            state.data = null;
            state.done = false;
            state.colis_list = [];
            state.colis_list_src = [];
            state.expeds_list = [] ;
        },
        selectColis: (state, action) => {
            state.selected_colis = action.payload;
        },
        checkColis: (state, action) => {
            console.log(action)
            if (action.payload) {
                
                let lst =
                    state.colis_list.map(item =>
                        item.ref === action.payload.data ? { ...item, checked: action.payload.etat } : item
                    )

                state.colis_list = lst;
            }
        },
        doFilter : (state , action) => {
            console.log(action.payload)
            if(action.payload.id != "")
                state.colis_list = state.colis_list_src.filter(elem => elem.user && elem.user.id  == action.payload.id )
            else
                state.colis_list = state.colis_list_src ;
        },
        setError : (state , action) => {
            console.log(action.payload)
            state.error = action.payload ;
        }


    },
    extraReducers: {
        [listColisAdm.fulfilled]: (state, action) => {
            if (action.payload) {
                state.colis_list = action.payload;
                state.colis_list_src = action.payload;
            }
            state.error = '';
            state.loading = false;

        },
        [listColisAdm.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [listColisAdm.pending]: (state, action) => {
            state.loading = true;
            state.colis_list = [];
            state.colis_list_src = [];
        },

        [searchAdmGlobalColis.fulfilled]: (state, action) => {
            if (action.payload) {
                state.colis_list = action.payload;
                state.colis_list_src = action.payload;
            }
            state.error = '';
            state.loading = false;

        },
        [searchAdmGlobalColis.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [searchAdmGlobalColis.pending]: (state, action) => {
            state.loading = true;
            state.colis_list = [];
            state.colis_list_src = [];
        },

        [getOneColisByFilter.fulfilled]: (state, action) => {
            if (action.payload) {
                state.data = action.payload;
            }
            state.error = '';
            state.loading = false;

        },
        [getOneColisByFilter.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [getOneColisByFilter.pending]: (state, action) => {
            state.loading = true;
        },
        [editColisInfo.fulfilled]: (state, action) => {
            if (action.payload) {
                if (action.payload.done) {
                    state.done = true;
                    state.data = action.payload.colis;
                }
            }
            state.error = '';
            state.loading = false;

        },
        [editColisInfo.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [editColisInfo.pending]: (state, action) => {
            state.loading = true;
            state.done = false ;
        },
        [del.fulfilled]: (state, action) => {
            if (action.payload) {
                if (action.payload.done) {
                    state.done = true;
                    
                }
            }
            state.error = '';
            state.loading = false;

        },
        [del.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [del.pending]: (state, action) => {
            state.loading = true;
            state.done = false ;
        },
        // [searchStatColis.fulfilled]: (state, action) => {
        //     if (action.payload) {
        //         state.colis_list = action.payload;
        //         state.colis_list_src = action.payload;
        //     }
        //     state.error = '';
        //     state.loading = false;

        // },
        // [searchStatColis.rejected]: (state, action) => {
        //     state.loading = false;
        //     state.error = action.payload;
        // },
        // [searchStatColis.pending]: (state, action) => {
        //     state.loading = true;
        //     state.colis_list = [];
        //     state.colis_list_src = [];
        // },
        // [searchAllColis.fulfilled]: (state, action) => {
        //     if (action.payload) {
        //         state.colis_list = action.payload;
        //         state.colis_list_src = action.payload;
        //     }
        //     state.error = '';
        //     state.loading = false;

        // },
        // [searchAllColis.rejected]: (state, action) => {
        //     state.loading = false;
        //     state.error = action.payload;
        // },
        // [searchAllColis.pending]: (state, action) => {
        //     state.loading = true;
        //     state.colis_list = [];
        //     state.colis_list_src = [];
        // },

        [getClientColisHistory.fulfilled]: (state, action) => {
            if (action.payload) {
                state.colis_list = action.payload;
                state.colis_list_src = action.payload;
            }
            state.error = '';
            state.loading = false;

        },
        [getClientColisHistory.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [getClientColisHistory.pending]: (state, action) => {
            state.loading = true;
            state.colis_list = [];
            state.colis_list_src = [];
        },
        [loadExpedsList.fulfilled]: (state, action) => {
            if (action.payload) {
                state.expeds_list = action.payload;
            }
            state.error = '';
            state.loading = false;

        },
        [loadExpedsList.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [loadExpedsList.pending]: (state, action) => {
            state.loading = true;
            state.expeds_list = [];
        },
    }
})

const { reducer } = colisSlice;
export const { clear, selectColis, checkColis , doFilter , setError } = colisSlice.actions;
export default reducer;