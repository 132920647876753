import axios from "axios";
import { api_url } from './http_constant';
import  storage from './UserStorage';

class AfexService {

    transfertAfexColis(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/afex/adm/transfert`, data, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    manifestAfexColis(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/afex/adm/manifest`, data, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

    listAfexColis(data) {
        let token = storage.getToken();
        return axios.post(`${api_url}/api/afex/adm/list`, data, {
            headers: {
                'cgo-tkn': token
            }
        });
    }

 

}

export default new AfexService();