import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import RunsheetService from '../services/RunsheetService';
import UserService from '../services/UserService';
import storage from '../services/UserStorage';

const initialState = {
    loading: false,
    error: '',
    done: false,
    item: null,
    item_list: [],
    selected_item: null,
    item_list_src : [],
    driver_list : [] ,
};

export const createRs = createAsyncThunk(
    "Rs/create",
    async (data, { rejectWithValue }) => {
        try {
            const res = await RunsheetService.createRs(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const editRs = createAsyncThunk(
    "Rs/edit",
    async (data, { rejectWithValue }) => {
        try {
            const res = await RunsheetService.editRs(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const deleteRs = createAsyncThunk(
    "Rs/del",
    async (data, { rejectWithValue }) => {
        try {
            const res = await RunsheetService.deleteRs(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const dispatchRs = createAsyncThunk(
    "Rs/dispatch",
    async (data, { rejectWithValue }) => {
        try {
            const res = await RunsheetService.dispatchRs(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const confirmRs = createAsyncThunk(
    "Rs/confirm",
    async (data, { rejectWithValue }) => {
        try {
            const res = await RunsheetService.confirmRs(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const getRsByCode = createAsyncThunk(
    "Rs/getbycode",
    async (data, { rejectWithValue }) => {
        try {
            const res = await RunsheetService.getRsByCode(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const getRsList = createAsyncThunk(
    "Rs/pending list",
    async (data, { rejectWithValue }) => {
        try {
            const res = await RunsheetService.getRsList(data)
            console.log(res)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const loadDriversDispo = createAsyncThunk(
    "Rs/load driver dispo",
    async (data, { rejectWithValue }) => {
        try {
            const res = await UserService.listAdmDriverDispo()
            console.log(res)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)
export const loadDrivers = createAsyncThunk(
    "Rs/load driver",
    async (data, { rejectWithValue }) => {
        try {
            const res = await UserService.listAdmDriver()
            console.log(res)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)




const muSlice = createSlice({
    name: "Rs",
    initialState,
    reducers: {
        clear: (state, action) => {
            state.loading = false;
            state.error = '';
            state.item = null;
            state.done = false;
            state.item_list = [];
            state.item_list_src = [];
            state.selected_item = null ;
            state.driver_list = [] ;
            
        },

        doSelect: (state, action) => {
            state.selected_item = action.payload;
        },
        doFilter : (state , action) => {
            console.log(action.payload)
            if(action.payload.id != "")
                state.item_list = state.item_list_src.filter(elem => elem.rsLiv && elem.rsLiv.id  == action.payload.id )
            else
                state.item_list = state.item_list_src ;
        }
    },
    extraReducers: {
       
        [createRs.fulfilled]: (state, action) => {
            if (action.payload) {
                if (action.payload.done) {
                    state.done = true;
                    
                }
            }
            state.error = '';
            state.loading = false;

        },
        [createRs.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [createRs.pending]: (state, action) => {
            state.loading = true;
            state.done = false ;
        },
        [editRs.fulfilled]: (state, action) => {
            if (action.payload) {
                if (action.payload.done) {
                    state.done = true;
                    
                }
            }
            state.error = '';
            state.loading = false;

        },
        [editRs.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [editRs.pending]: (state, action) => {
            state.loading = true;
            state.done = false ;
        },
        [deleteRs.fulfilled]: (state, action) => {
            if (action.payload) {
                if (action.payload.done) {
                    state.done = true;
                    
                }
            }
            state.error = '';
            state.loading = false;

        },
        [deleteRs.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [deleteRs.pending]: (state, action) => {
            state.loading = true;
            state.done = false ;
        },
        [dispatchRs.fulfilled]: (state, action) => {
            if (action.payload) {
                if (action.payload.done) {
                    state.done = true;
                    state.item = action.payload.rs;
                }
            }
            state.error = '';
            state.loading = false;

        },
        [dispatchRs.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [dispatchRs.pending]: (state, action) => {
            state.loading = true;
            state.done = false ;
        },
        [confirmRs.fulfilled]: (state, action) => {
            if (action.payload) {
                if (action.payload.done) {
                    state.done = true;
                    
                }
            }
            state.error = '';
            state.loading = false;

        },
        [confirmRs.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [confirmRs.pending]: (state, action) => {
            state.loading = true;
            state.done = false ;
        },
        [getRsList.fulfilled]: (state, action) => {
            if (action.payload) {
                state.item_list = action.payload;
                state.item_list_src = action.payload;
            }
            state.error = '';
            state.loading = false;

        },
        [getRsList.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [getRsList.pending]: (state, action) => {
            state.loading = true;
            state.item_list = [];
            state.item_list_src = [];
        },
       
        [getRsByCode.fulfilled]: (state, action) => {
            if (action.payload) {
                state.item = action.payload;
            }
            state.error = '';
            state.loading = false;

        },
        [getRsByCode.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [getRsByCode.pending]: (state, action) => {
            state.loading = true;
            state.item = null;
        },
    
        [loadDriversDispo.fulfilled]: (state, action) => {
            if (action.payload) {
                state.driver_list = action.payload;
            }
            state.error = '';
            state.loading = false;

        },
        [loadDriversDispo.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [loadDriversDispo.pending]: (state, action) => {
            state.loading = true;
            state.driver_list = [];
        },
        [loadDrivers.fulfilled]: (state, action) => {
            if (action.payload) {
                state.driver_list = action.payload;
            }
            state.error = '';
            state.loading = false;

        },
        [loadDrivers.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [loadDrivers.pending]: (state, action) => {
            state.loading = true;
            state.driver_list = [];
        },
        
    }
})

const { reducer } = muSlice;
export const { clear, doSelect  , doFilter } = muSlice.actions;
export default reducer;