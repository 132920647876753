import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import MovableService from '../services/MovableService';
import storage from '../services/UserStorage';

const initialState = {
    loading: false,
    error: '',
    done: false,
    item: null,
    item_list: [],
    selected_item: null,
    search_colis : [],
    item_list_src : [],
};

export const create = createAsyncThunk(
    "mu/create",
    async (data, { rejectWithValue }) => {
        try {
            const res = await MovableService.createClientMu(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)


export const list = createAsyncThunk(
    "mu/list",
    async (data, { rejectWithValue }) => {
        try {
            const res = await MovableService.getClientMuList(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const searchColis = createAsyncThunk(
    "mu/searchColis",
    async (data, { rejectWithValue }) => {
        try {
            const res = await MovableService.searchMuColis(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const getByCode = createAsyncThunk(
    "mu/getByCode",
    async (data, { rejectWithValue }) => {
        try {
            const res = await MovableService.getClientMuByCode(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const edit = createAsyncThunk(
    "mu/edit",
    async (data, { rejectWithValue }) => {
        try {
            const res = await MovableService.editClientMu(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const del = createAsyncThunk(
    "mu/del",
    async (data, { rejectWithValue }) => {
        try {
            const res = await MovableService.cancelClientMu(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)
export const dispatchMu = createAsyncThunk(
    "mu/dispatch",
    async (data, { rejectWithValue }) => {
        try {
            const res = await MovableService.dispatchClientMu(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const getClientHistory = createAsyncThunk(
    "mu/history",
    async (data, { rejectWithValue }) => {
        try {
            const res = await MovableService.getClientMuHistory(data)
            console.log(res)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)



const muSlice = createSlice({
    name: "mu",
    initialState,
    reducers: {
        clear: (state, action) => {
            state.loading = false;
            state.error = '';
            state.item = null;
            state.done = false;
            state.item_list = [];
            state.item_list_src = [];
            state.selected_item = null ;
            state.search_colis = []
        },
        doSelect: (state, action) => {
            state.selected_item = action.payload;
        },
        doFilter : (state , action) => {
            console.log(action.payload)
            if(action.payload.etat != "")
            state.item_list = state.item_list_src.filter(elem => elem.etat == action.payload.etat )
            else
            state.item_list = state.item_list_src ;
        }

    },
    extraReducers: {
        [create.fulfilled  ]: (state, action) => {
            if (action.payload) {
                if (action.payload.done) {
                    state.done = true;
                    state.item = action.payload.mu;
                }
            }
            state.error = '';
            state.loading = false;

        },
        [create.rejected ]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [create.pending  ] : (state, action) => {
            state.loading = true;
            state.done = false ;
        },
        [list.fulfilled]: (state, action) => {
            if (action.payload) {
                state.item_list = action.payload;
                state.item_list_src = action.payload;
            }
            state.error = '';
            state.loading = false;

        },
        [list.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [list.pending]: (state, action) => {
            state.loading = true;
            state.item_list = [];
            state.item_list_src = [];
        },
        [edit.fulfilled]: (state, action) => {
            if (action.payload) {
                if (action.payload.done) {
                    state.done = true;
                    state.item = action.payload.mu;
                }
            }
            state.error = '';
            state.loading = false;

        },
        [edit.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [edit.pending]: (state, action) => {
            state.loading = true;
            state.done = false ;
        },
        [del.fulfilled]: (state, action) => {
            if (action.payload) {
                if (action.payload.done) {
                    state.done = true;
                    
                }
            }
            state.error = '';
            state.loading = false;

        },
        [del.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [del.pending]: (state, action) => {
            state.loading = true;
            state.done = false ;
        },
        [searchColis.fulfilled]: (state, action) => {
            if (action.payload) {
                state.search_colis = action.payload;
            }
            state.error = '';
            state.loading = false;

        },
        [searchColis.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [searchColis.pending]: (state, action) => {
            state.loading = true;
            state.search_colis = [];
        },
        [getByCode.fulfilled]: (state, action) => {
            if (action.payload) {
                state.item = action.payload;
            }
            state.error = '';
            state.loading = false;

        },
        [getByCode.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [getByCode.pending]: (state, action) => {
            state.loading = true;
            state.item = null;
        },
        [dispatchMu.fulfilled]: (state, action) => {
            if (action.payload) {
                if (action.payload.done) {
                    state.done = true;
                    
                }
            }
            state.error = '';
            state.loading = false;

        },
        [dispatchMu.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [dispatchMu.pending]: (state, action) => {
            state.loading = true;
            state.done = false ;
        },
        [getClientHistory.fulfilled]: (state, action) => {
            if (action.payload) {
                state.item_list = action.payload;
                state.item_list_src = action.payload;
            }
            state.error = '';
            state.loading = false;

        },
        [getClientHistory.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [getClientHistory.pending]: (state, action) => {
            state.loading = true;
            state.item_list = [];
            state.item_list_src = [];
        },
        
    }
})

const { reducer } = muSlice;
export const { clear, doSelect , doFilter } = muSlice.actions;
export default reducer;