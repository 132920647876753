import React, { useState, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import Barcode from 'react-barcode';
import logo from '../../../assets/img/logo.png'
import UserStorage from '../../../services/UserStorage';
import { routes } from '../../../utils/helpers/routing.helper';
import { company_info } from '../../../utils/constants/company.info';

const RunsheetPrintFiche = (props) => {

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const [rs, setRs] = useState(UserStorage.getParam());
    const [list, setList] = useState([]);
    const [date, setDate] = useState(new Date());

    //routing 
    const history = useHistory();

    const getMontant = () => {
        let m = 0;
        m = list.reduce((a, b) => a + +b.montant_ttc, 0.000)
        console.log(m)
        return parseFloat(m).toFixed(3);
    }


    useEffect(() => {
        if (rs) {
            if (Array.isArray(rs.colis))
                setList(rs.colis)
        } else {
            history.push(routes.rs_adm_reco)
        }


    }, []);

    return (
        <div className="col-md-12 bg-wt">
            <div className="col-md-12 pt-2 pb-2 ps-4">
                <button className="btn btn-outline-success" onClick={handlePrint}> Imprimer Fiche </button>
            </div>


            <div className="row no-marg" ref={componentRef}>

                <div className="col-12">
{/* 
                    <div className="row">


                        <div className="col-4 fnt-sm pt-2">
                            <span> <b>Driver :</b>  {rs && rs.rsLiv && rs.rsLiv.nom + " " + rs.rsLiv.prenom}</span> <br />
                            <b>Ref Runsheet : <span className="fnt-w5">{rs && rs.ref}</span></b> <br />
                            <b>Date : <span className="fnt-w5">{date.getDate()}/{(date.getMonth() + 1)}/{date.getFullYear()}</span></b> <br />
                        </div>

                        <div className="col-4 txt-ctr">
                            <img src={logo} alt="" width="60px" />
                        </div>

                        <div className="col-4 fnt-sm  pt-2">
                            <b>Nombre colis : <span className="fnt-w5">{rs && list.length}</span></b> <br />
                            <b>Montant total : <span className="fnt-w5">{rs && getMontant()} TND</span></b> <br />
                        </div>
                    </div> */}

                </div>

                <div className="col-md-12">
                    <div className="row">

                        <div className="col-md-1"></div>
                        <div className="col-md-12 no-padd">
                            <table width="94%" className="table table-striped table-bordered  mt-2 fnt-sm" >
                                <thead className="txt-ctr">
                                <tr>
                    <td className="text-start">
                      <b className="d-block">
                        <span className="fnt-w5">Date:</span> {String(rs?.createdAt)?.substring(0,10)} <br/>
                        <span className="fnt-w5">Chauffeur :</span> {rs?.rsLiv?.nom + " " + rs?.rsLiv?.prenom} <br/>
                        <span className="fnt-w5">Matricule :</span> {rs?.rsLiv?.matricule} <br/>
                      </b>
                    </td>

                    <td className="text-center" colSpan={4}>
                      <img src={logo} alt="" width="82px" />
                      <h6>Tournée</h6>
                      <h6 className="fnt-w7">{rs?.ref}</h6>
                      <b>M/F:{company_info?.mf}</b>
                    </td>

                    <td className="text-start" colSpan={2}>
                      <b className="d-block">
                        <span className="fnt-w5">Nbr Colis:</span> {Array.isArray(list) ? list?.length : '---'}  <br/>
                        <span className="fnt-w5">Montant Colis:</span> {rs && getMontant()} TND <br/>
                      </b>
                    </td>

                                </tr>
                                    <tr>
                                    <th>Colis</th>
                                    <th>Tentative</th>
                                    <th>client</th>
                                    <th>Note</th>
                                    <th>Montant</th>
                                    <th>Type Envoie</th>
                                    <th></th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {rs && list.map((data, index) =>
                                        <tr>
                                            <td className="p-1 fnt-sm" ><Barcode value={data.code} width={1} height={30} style={{fontSize : "small"}} /></td>
                                            <td className="fnt-sm">{data.attempt}/3</td>
                                            <td className="p-1 fnt-sm">
                                                <b>Nom: </b>{data.nom_cl} {data.prenom_cl} <br />
                                                <b>Tél: </b>{data.tel_cl} <br />
                                                <b>Adresse: </b>{data.adresse} <br />
                                                <b>{data.adresse_lieu} </b><br />

                                            </td>
                                            {/* <td className="p-1 fnt-sm">
                                                <b>{data.user && data.user.nom_com}</b><br />
                                                <b>Tél: </b>{data.user && data.user.tel} <br />

                                            </td> */}
                                            <td className="p-1 fnt-sm">
                                                {data.note}
                                            </td>
                                            <td><b>{data.montant_ttc} </b>TND</td>

                                            <td style={{textTransform : 'uppercase'}}><b>{data.type_envoi}</b></td>
                                            <td className="p-1" style={{  height: "70px" , width : "20%" }} > </td>
                                        </tr>
                                    )}







                                </tbody>
                            </table>


                        </div>
                    </div>

                </div>


            </div>


        </div>
    );
}
export default RunsheetPrintFiche;