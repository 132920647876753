import React from "react";
import Item from "../items/ColisClient.item";
import noDataIcon from "../../assets/img/no_data.png";
import { Form, Table } from "react-bootstrap";
import { ColisAfexStatusBg, ColisAfexStatusLabels, stat as colisStat, ColisStatus, ColisStatusBg, colisStatusLabels, ColisTypeEnvoie, ColisTypeEnvoieLabels } from "../../utils/constants/Colis.enum";
import { getDays, getStringDate } from "../../utils/helpers/date.helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ColisList = ({ loading, lst_data, onItemDetailsClick, onItemSelected, onSelectAll }) => {
  return (
    <div className="col-md-12 bg-wt mt-2">
      {
        loading ? (
          <div class="col-md-12 p-4 txt-ctr">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          Array.isArray(lst_data) && <ColisTable lst_data={lst_data} onItemClick={onItemDetailsClick} onSelect={onItemSelected} onSelectAll={onSelectAll} />
        )
        // (
        //     Array.isArray(lst_data) &&
        //     lst_data.map((s, index) => <Item key={`colis_.${index}`} data={s} onItemDetailsClick={onItemDetailsClick} onSelect={onItemSelected} />)

        // )
      }

      {!lst_data.length && !loading && (
        <div className="col-md-12 txt-ctr">
          <br />
          <br />
          <br />
          <img src={noDataIcon} alt="" width="10%" />
          <br />
          <b className="txt-gray fnt-w5">NO DATA FOUND</b>
        </div>
      )}
    </div>
  );
};

const ColisTable = ({ lst_data, onItemClick, onSelect, onSelectAll }) => {
  const renderStat = (data) => {
    let spn_stat;
    let stat = "";
    switch (data.etat) {
      case colisStat.pd_transfer:
        stat = "En attente de transfert";
        break;
      case colisStat.pd_dispatch:
        stat = "A confirmer le transfert";
        break;

      case colisStat.pd_receive:
        stat = "En attente de ramassage";
        break;
      case colisStat.pickup:
        stat = "En attente de livraison";
        break;
      case colisStat.pickup_prog:
        stat = "En cours de ramassage";
        break;
      case colisStat.pickup_fail:
        stat = "Echec de ramassage";
        break;
      case colisStat.in_rs:
        stat = "Livraison programmé";
        break;
      case colisStat.progress:
        stat = "En cours de livraison";
        break;
      case colisStat.progress_success:
        stat = "Livré";
        break;
      case colisStat.progress_fail:
        stat = "Echec";
        break;
      case colisStat.progress_reported:
        stat = "Reporté";
        break;

      case colisStat.completed:
        if (data.livre) stat = "Terminé (En attente de payement)";
        else stat = "Terminé (Echec de livraison)";
        break;
      case colisStat.to_return:
        stat = "Echec(En attente de retour)";
        break;
      case colisStat.return_progress:
        stat = "Retour (en cours )";
        break;

      case colisStat.closed:
        stat = "Fermé";
        break;

      default:
        stat = data.etat;
        break;
    }

    if (data.etat === colisStat.pd_receive || data.etat === colisStat.in_rs || data.etat === colisStat.progress || data.etat === colisStat.pd_transfer || data.etat === colisStat.pd_dispatch) {
      spn_stat = <span className="badge bg-warning text-dark fnt-w5"> {stat} </span>;
    } else if (data.etat === colisStat.pickup || data.etat === colisStat.completed || data.etat === colisStat.progress_success) {
      spn_stat = <span className="badge bg-success fnt-w5 txt_wt"> {stat} </span>;
    } else if (data.etat === colisStat.progress_fail || data.etat === colisStat.to_return) {
      spn_stat = <span className="badge bg-danger fnt-w5 txt_wt"> {stat}</span>;
    } else if (data.etat === colisStat.progress_reported) {
      spn_stat = <span className="badge bg-danger bg-org fnt-w5 txt_wt"> {stat}</span>;
    } else {
      spn_stat = <span className="badge bg-secondary fnt-w5 txt_wt"> {stat}</span>;
    }
    return spn_stat;
  };

  return (
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th className="text-center">
            <Form.Group id="formGridCheckboxAll" className="no-marg">
              <Form.Check type="checkbox" className="d-inline-block" id="chk-all" onChange={(e) => onSelectAll(e.target.checked)} />
            </Form.Group>
          </th>
          <th className="text-center">Numéro</th>
          <th className="text-center">Status</th>
          <th className="text-center">Type d'envoi</th>
          <th className="text-center">Tentatives</th>
          <th className="text-center">Client</th>
          <th className="text-center">Créer le</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {Array.isArray(lst_data) &&
          lst_data.map((el) => (
            <tr key={el?.code}>
              <td className="text-center">
                <Form.Group id="formGridCheckbox" className="no-marg">
                  <Form.Check type="checkbox" className="d-inline-block" id="chk" checked={el.checked} onChange={(e) => onSelect(el.ref, e.target.checked)} />
                </Form.Group>
              </td>
              <td className="text-center fnt-w5">{el?.code}</td>
              {el?.is_afex ? (
                <td>
                  <span class={`badge badge-pill  ${ColisAfexStatusBg[el?.etat_afex]}`}>{ColisAfexStatusLabels[el?.etat_afex]} </span>{" "}
                </td>
              ) : (
                <td>
                  {String(el?.etat).includes("LIV-") && el?.type_envoi == ColisTypeEnvoie.recuperation ? (
                    <span class={`badge badge-pill  ${ColisStatusBg[el?.etat]}`}>{String(colisStatusLabels[el?.etat]).replace("Livraison", "Récupération")} </span>
                  ) : (
                    <span class={`badge badge-pill  ${ColisStatusBg[el?.etat]}`}>{colisStatusLabels[el?.etat]} </span>
                  )}
                </td>
              )}
              <td className="fnt-w5">{`${ColisTypeEnvoieLabels[el?.type_envoi]}`}</td>
              <td className="text-center">
                <span class={el.attempt < 3 ? "badge bg-dark" : "badge bg-danger txt_wt"}>{el.attempt}/3</span>
              </td>
              <td className="text-center">
                {el.nom_cl} {el.prenom_cl}{" "}
              </td>
              <td className="text-center">
                <b className="sm-gray-txt fnt-sm">{el.createdAt ? getStringDate(el.createdAt) : "_"}</b>
                {/* <span className="fnt-w5 fnt-sm">{el.createdAt ? getDays(el.createdAt) : '_'}</span> */}
              </td>
              <td className="text-center">
                <button className="box_item_btn no-marg btn-sm" onClick={(e) => onItemClick(el)}>
                  <FontAwesomeIcon icon={["fas", "long-arrow-alt-right"]} />
                </button>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

export default ColisList;
