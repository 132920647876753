import React , { useState , useEffect} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import Form from 'react-bootstrap/Form'
import fastIcon from '../../../assets/img/fast.svg';
import receiveIcon from '../../../assets/img/stopwatch_blue.svg';
import todoIcon from '../../../assets/img/product.svg';
import toreturnIcon from '../../../assets/img/return.svg';
import orderIcon from '../../../assets/img/order.svg';
import pickupIcon from '../../../assets/img/trolley.svg';
import driverIcon from '../../../assets/img/driver.svg';
import clientIcon from '../../../assets/img/man.svg';

import UserStorage from '../../../services/UserStorage';
import axios from 'axios';
import { api_url } from '../../../services/http_constant';

const Home = ({ match }) => {

    const [user, setUser] = useState(UserStorage.getData());
    const [token, setToken] = useState(UserStorage.getToken());
    const [to_pay, setTo_pay] = useState(0.0);
    const [to_transfer, setTo_transfer] = useState(0);
    const [in_prog, setIn_prog] = useState(0);
    const [to_return, setTo_return] = useState(0);
    const [nbr_livre, set_nbr_livre] = useState(0);
    const history = useHistory();

    const loadData = ()=>{
        axios.post(`${api_url}/api/users/client/resume`,{}, {
            headers: {
                'cgo-tkn': token
            }
        })
            .then(res => {
                console.log(res.data);
                if(res.data){
                    setTo_pay(res.data.to_pay);
                    setTo_transfer(res.data.to_transfer);
                    setIn_prog(res.data.in_prog);
                    setTo_return(res.data.to_return);
                    set_nbr_livre(res.data.livre)


                }
            })
            .catch(error => {
                // setLoading(false);
                if (error.response) {
                    console.log(error.response.data.message);
                    if( error.response.data.message === "invalid-token"){
                        UserStorage.logout();
                        history.push("/login");
                    }
                }
            }
            );
    }

    useEffect(() => {
        loadData();
        console.log(UserStorage.getData())

    }, []);


    return (

        <>

            <div className="row no-marg  pb-3 pt-3">

                <div className="col-12 txt-ctr ">
                    <h4 className="item_box_money no-marg">{parseFloat(to_pay).toFixed(3)} <span>TND</span> </h4>
                    <p className="no-marg fnt-w5">crédit</p>
                </div>
               
            </div>

            <div className="row  no-marg">

                <div className="col-12 no-padd text-center">
                    <br />
                    {/* <h5 className="mini_title col-3  fnt-w4 text-center">Colis</h5> */}
                </div>


                <div className="col-6 col-md-3 offset-md-3 txt-ctr  p-2">
                    <div className="col-md-12 bg-wt home-item">
                        <img src={orderIcon} width="42px" alt="" />
                        <h3 className="no-marg">{in_prog}</h3>
                        <span className="fnt-w4">En cours de livraison</span>
                    </div>

                </div>
                <div className="col-6 col-md-3 txt-ctr  p-2">
                    <div className="col-md-12 bg-wt home-item">
                        <img src={todoIcon} width="42px" alt="" />
                        <h3 className="no-marg">{to_transfer}</h3>
                        <span className="fnt-w4">A Envoyer</span>
                    </div>
                </div>
                <div className="col-6 col-md-3 offset-md-3 txt-ctr  p-2">
                    <div className="col-md-12 bg-wt home-item">
                        <img src={pickupIcon} width="42px" alt="" />
                        <h3 className="no-marg">{nbr_livre}</h3>
                        <span className="fnt-w4">Livré</span>
                    </div>
                </div>
                <div className="col-6 col-md-3 txt-ctr  p-2">
                    <div className="col-md-12 bg-wt home-item">
                        <img src={toreturnIcon} width="42px" alt="" />
                        <h3 className="no-marg">{to_return}</h3>
                        <span className="fnt-w4">Retour</span>

                    </div>
                </div>


            </div>



        </>
    )

}

export default Home;