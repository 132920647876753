/* eslint-disable */
import React, { useState, useEffect } from 'react';
import FactureAdmList from '../../../ui/lists/FactureAdm.list';
import FactureAdmHeader from '../../../ui/headers/FactureAdm.header';
import { clear, listFacture, cancelFacture, doSelect } from '../../../slices/FactureAdm';
import ConfirmModal from '../../../ui/modals/ConfirmModal';
import { useDispatch, useSelector } from "react-redux";
import { listAdmExped } from "../../../slices/User.slice";


const Bill = (props) => {

    const dispatch = useDispatch();
    const { loading, error, item_list, selected_item } = useSelector(state => state.bill_adm);

    const [showDelModal, setShowDelModal] = useState(false);
    const hideDelMod = () => { setShowDelModal(false) }
    const doShowDelModal = () => { setShowDelModal(true) }
    const [mod_del_title, set_mod_del_title] = useState("");
    const [mod_del_msg, set_mod_del_msg] = useState("");

    const [showPayModal, setShowPayModal] = useState(false);
    const hidePayMod = () => { setShowPayModal(false) }
    const doShowPayModal = () => { setShowPayModal(true) }
    const [mod_pay_title, set_mod_pay_title] = useState("");
    const [mod_pay_msg, set_mod_pay_msg] = useState("");

    const [lst_gov, set_lst_gov] = useState([]);

    const [lst_exped, set_lst_exped] = useState([]);
    


    const onPay = data => {
        if (data) {
            dispatch(doSelect(data))
            set_mod_pay_title("Payement facture")
            set_mod_pay_msg("Voulez-vous confirmer le payement de la facture " + data.code + " ?")
            setShowPayModal(true);

        }
    }

    // const doPay = () => {
    //     if (selected_item) {
    //         dispatch(payBills({ ids: [selected_item.id] })).unwrap().then(d => {
    //             hidePayMod()
    //             dispatch(list())
    //         })
    //     }
    // }

    const onPrint = data => {
        if (data) {
            //UserStorage.setParam(data)
            const win = window.open(`/dashb/bill/print/${data?.ref}`, "_blank");
            win.focus();
        }
    }

    const onDelete = data => {
        if (data) {
            dispatch(doSelect(data))
            set_mod_del_title("Supprimer facture")
            set_mod_del_msg("Voulez-vous supprimer la facture " + data?.ref + " ?")
            setShowDelModal(true);

        }
    }

    const doDelete = () => {
        if (selected_item) {
            console.log(selected_item)
            dispatch(cancelFacture({ id: selected_item.id })).unwrap().then(() => {
                setShowDelModal(false);
                dispatch(listFacture());
            })
        }
    }


    const load_data = (filter) => {
        dispatch(listFacture({filter : filter}))
    }

    
    const load_expeds = () => {
        dispatch(listAdmExped())
          .unwrap()
          .then((res) => {
            set_lst_exped(Array.isArray(res) ? res : []);
          });
      };

   
    useEffect(() => {
        load_expeds()
        load_data()
        return () => {
            dispatch(clear());
        }
    }, [])

    return (
        <>
            <FactureAdmHeader lst_data={item_list} error={error} loading={loading} load_data={load_data} lst_exped={lst_exped}  />
            <FactureAdmList loading={loading} error={error} lst_data={item_list} onPrint={onPrint}  onDelete={onDelete} />
            <ConfirmModal onHide={hideDelMod} onOk={doDelete} title={mod_del_title} msg={mod_del_msg} show={showDelModal} loading={loading} />
            {/* <ConfirmModal onHide={hidePayMod} onOk={doPay} title={mod_pay_title} msg={mod_pay_msg} show={showPayModal} loading={loading} /> */}

        </>
    )
}

export default Bill;



