import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { clear, listAdmTicket, applyAdmTicket, refuseAdmTicket, cancelAdmTicket } from "../../../slices/Ticket.slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Table } from "react-bootstrap";
import { TicketStatus, TicketStatusBg, TicketTypeLabel } from "../../../utils/constants/ticket.enum";
import ConfirmModal from "../../../ui/modals/ConfirmModal";
import TicketAdmDeatilsContainer from "./TicketAdmDeatils.container";

const TicketAdmListContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, error } = useSelector((state) => state.ticket);

  const [lst_ticket, set_lst_ticket] = useState([]);
  const [sel_item, set_sel_item] = useState(null);
  const [filter, set_filter] = useState({ search: "" });

  const [show_details, set_show_details] = useState(false);
  const [show_del_modal, set_show_del_modal] = useState(false);
  const [show_apply_modal, set_show_apply_modal] = useState(false);
  const [show_refuse_modal, set_show_refuse_modal] = useState(false);

  const load_data = () => {
    let tmp_filter = {};
    if (filter?.search) tmp_filter.search = filter?.search;
    dispatch(listAdmTicket({ filter: tmp_filter }))
      .unwrap()
      .then((res) => {
        set_lst_ticket(Array.isArray(res) ? res : []);
      })
      .catch((err) => console.log(err));
  };

  const onShowModal = (item, modal) => {
    if (item) {
      set_sel_item(item);

      if (modal == "cancel") set_show_del_modal(true);
      else if (modal == "apply") set_show_apply_modal(true);
      else if (modal == "refuse") set_show_refuse_modal(true);
    }
  };

  const onCloseModal = (modal) => {
    set_sel_item(null);

    if (modal == "cancel") set_show_del_modal(false);
    else if (modal == "apply") set_show_apply_modal(false);
    else if (modal == "refuse") set_show_refuse_modal(false);
  };

  const onCancelTicket = () => {
    if (sel_item?.id)
      dispatch(cancelAdmTicket(sel_item?.id))
        .unwrap()
        .then((res) => {
          if (res?.done) {
            onCloseModal('cancel');
            load_data();
          }
        })
        .catch((err) => console.log(err));
  };

  const onApplyTicket = () => {
    if (sel_item?.id)
      dispatch(applyAdmTicket(sel_item?.id))
        .unwrap()
        .then((res) => {
          if (res?.done) {
            onCloseModal('apply');
            load_data();
          }
        })
        .catch((err) => console.log(err));
  };

  const onRefuseTicket = () => {
    if (sel_item?.id)
      dispatch(refuseAdmTicket(sel_item?.id))
        .unwrap()
        .then((res) => {
          if (res?.done) {
            onCloseModal('refuse');
            load_data();
          }
        })
        .catch((err) => console.log(err));
  };

  const onViewTicket = (data) => {
    if (data) {
      set_sel_item(data);
      set_show_details(true);
    }
  };

  const onCloseDetails = () => {
    set_sel_item(null);
    set_show_details(false);
  };

  useEffect(() => {
    load_data();
    return () => {
      dispatch(clear());
    };
  }, []);

  return (
    <>
      <TicketListHeader filter={filter} set_filter={set_filter} load_data={load_data} error={error} />
      <TicketTable lst_ticket={lst_ticket} loading={loading} onShowModal={onShowModal} onViewDetails={onViewTicket} />
      <ConfirmModal title="Annulation de ticket" msg={`Voulez-vous annuler le ticket Num ${sel_item?.ref} ?`} show={show_del_modal} onHide={e => onCloseModal('cancel')} onOk={onCancelTicket} loading={loading} error={error} />
      <ConfirmModal title="Application de ticket" msg={`Voulez-vous Appliquer les chagement du ticket Num ${sel_item?.ref} ?`} show={show_apply_modal} onHide={e => onCloseModal('apply')} onOk={onApplyTicket} loading={loading} error={error} />
      <ConfirmModal title="Refus de ticket" msg={`Voulez-vous refuser le ticket Num ${sel_item?.ref} ?`} show={show_refuse_modal} onHide={e => onCloseModal('refuse')} onOk={onRefuseTicket} loading={loading} error={error} />

      <TicketAdmDeatilsContainer show={show_details} onClose={onCloseDetails} ticket={sel_item} />
    </>
  );
};

const TicketListHeader = ({ load_data, filter, set_filter, error }) => {
  const onClearFilter = () => {
    set_filter({ search: "" });
    setTimeout(() => load_data, 1000);
  };

  return (
    <>
      <div className="row no-marg tit_rw">
        <div className="col-md-6 no-padd dsp_inl_flex">
          <b className="tit_icon_shape">
            <FontAwesomeIcon icon={["fas", "ticket-alt"]} />
          </b>
          <b className="tit_spacer_shape"></b>
          <b className="tit_text_shape">Les Tickets </b>
        </div>

        <div className="col-md-6 no-padd dsp_inl_flex box_resum ">
          <b className="box_resum-b padd-l10 dvdr-l-fx-gray">
            <span></span>
          </b>
        </div>
      </div>

      <div className="row no-marg rw-header">
        <div className="row m-0 p-2">
          <Form.Group className="col-md-6 px-2">
            <Form.Label className="fnt-sm fnt-w5 m-0">Recherche</Form.Label>
            <Form.Control
              type="search"
              className="form-control inpt_search shadow-none"
              style={{ borderRadius: ".2rem" }}
              placeholder="Recherche par (Réf / Code Colis) ..."
              onChange={(e) => set_filter({ ...filter, search: e.target.value })}
              value={filter?.search}
            />
          </Form.Group>

          <Form.Group className="col-md-3 px-2 pt-4">
            <button className="btn btn_search m-0" onClick={load_data}>
              <FontAwesomeIcon icon={["fas", "search"]} />
            </button>
            <button className="btn btn_search bg-success m-0 ms-1" onClick={load_data}>
              <FontAwesomeIcon icon={["fas", "sync-alt"]} />
            </button>
            <button className="btn btn_search bg-secondary m-0 ms-1" onClick={onClearFilter}>
              <FontAwesomeIcon icon={["fas", "times"]} />
            </button>
          </Form.Group>
        </div>

        <div className="col-md-12 d-inline-block text-end py-1">
        </div>
      </div>

      <div className="col-md-12">
        {error && (
          <div className="alert alert-danger" role="alert">
            <FontAwesomeIcon icon={["fas", "exclamation-circle"]} /> <span className="ps-3">{error}</span>
          </div>
        )}
      </div>
    </>
  );
};

const TicketTable = ({ lst_ticket, loading, onShowModal, onViewDetails }) => {
  return (
    <div className="row m-0">
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Réf</th>
            <th>Expéditeur</th>
            <th>Type</th>
            <th>Colis</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(lst_ticket) &&
            lst_ticket.map((el) => (
              <tr>
                <td>{el?.ref}</td>
                <td>{el?.user?.nom_com}</td>
                <td>{TicketTypeLabel[el?.type]}</td>
                <td className="text-success fnt-w5">
                  <FontAwesomeIcon icon={["fas", "barecode"]} /> {el?.coli?.code}
                </td>
                <td>
                  <span className={`badge text-capitalize ${TicketStatusBg[el?.status]}`}>{el?.status}</span>
                </td>
                <td>
                  
                  {el?.status == TicketStatus["EN-ATTENTE"] && (
                    <button className="btn btn-sm btn-success me-1" disabled={loading} onClick={(e) => onShowModal(el , 'apply')}>
                      <FontAwesomeIcon icon={["fas", "check-circle"]} /> Appliquer
                    </button>
                  )}
                  {el?.status == TicketStatus["EN-ATTENTE"] && (
                    <button className="btn btn-sm btn-danger me-1" disabled={loading} onClick={(e) => onShowModal(el , 'refuse')}>
                      <FontAwesomeIcon icon={["fas", "ban"]} /> Refuser
                    </button>
                  )}

                  <button className="btn btn-sm btn-primary me-1" disabled={loading} onClick={(e) => onViewDetails(el)}>
                    <FontAwesomeIcon icon={["fas", "eye"]} /> Détails
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default TicketAdmListContainer;
