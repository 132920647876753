import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FactureService from '../services/FactureService';
import storage from '../services/UserStorage';

const initialState = {
    loading: false,
    error: '',
    done: false,
    item: null,
    item_list: [],
    selected_item: null,
    search_colis: [],
    item_list_src: [],
    client: null,
};


export const createFacture = createAsyncThunk(
    "bill/create",
    async (data, { rejectWithValue }) => {
        try {
            const res = await FactureService.createAdmFacture(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const cancelFacture = createAsyncThunk(
    "bill/cancel",
    async (data, { rejectWithValue }) => {
        try {
            const res = await FactureService.cancelAdmFacture(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

// export const payBills = createAsyncThunk(
//     "bill/pay",
//     async (data, { rejectWithValue }) => {
//         try {
//             const res = await FactureService.payBills(data)
//             return res.data;
//         } catch (error) {
//             const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
//             return rejectWithValue(message)
//         }
//     }
// )

export const listFacture = createAsyncThunk(
    "bill/bill-list",
    async (data, { rejectWithValue }) => {
        try {
            const res = await FactureService.listAdmFacture(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const listDettes = createAsyncThunk(
    "bill/listeDettes",
    async (data, { rejectWithValue }) => {
        try {
            const res = await FactureService.getAdmDettes(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const listDettesByClient = createAsyncThunk(
    "bill/listDettesByClient",
    async (data, { rejectWithValue }) => {
        try {
            const res = await FactureService.getAdmDettesByClient(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)
export const getFactureByCode = createAsyncThunk(
    "bill/getFactureByCode",
    async (data, { rejectWithValue }) => {
        try {
            const res = await FactureService.getAdmFactureByCode(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)
// export const searchBills = createAsyncThunk(
//     "bill/search-bills",
//     async (data, { rejectWithValue }) => {
//         try {
//             const res = await FactureService.searchBills(data)
//             return res.data;
//         } catch (error) {
//             const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
//             return rejectWithValue(message)
//         }
//     }
// )


const billSlice = createSlice({
    name: "Bill-ADM",
    initialState,
    reducers: {
        clear: (state, action) => {
            state.loading = false;
            state.error = '';
            state.item = null;
            state.done = false;
            state.item_list = [];
            state.item_list_src = [];
            state.selected_item = null;
            state.search_colis = [];
            state.client = null
        },
        doSelect: (state, action) => {
            state.selected_item = action.payload;
        },
        doFilter: (state, action) => {
            console.log(action.payload)
            if (action.payload.etat != "")
                state.item_list = state.item_list_src.filter(elem => elem.etat == action.payload.etat)
            else
                state.item_list = state.item_list_src;
        },

        checkColis: (state, action) => {
            console.log(action)
            if (action.payload && state.client) {
                if (Array.isArray(state.client.colis)) {
                    let lst =
                        state.client.colis.map(item =>
                            item.ref === action.payload.ref ? { ...item, checked: action.payload.etat } : item
                        )
                    state.client = { ...state.client, colis: lst };
                }
            }
        },
        checkAllColis: (state, action) => {
            console.log(action)
            if (action.payload && state.client) {
                if (Array.isArray(state.client.colis)) {
                    let lst =
                        state.client.colis.map(item => ({ ...item, checked: action.payload.etat }))
                    state.client = { ...state.client, colis: lst };
                }
            }
        },

    },
    extraReducers: {
        [createFacture.fulfilled]: (state, action) => {
            if (action.payload) {
                if (action.payload.done) {
                    state.done = true;
                    state.item = action.payload.bill;
                }
            }
            state.error = '';
            state.loading = false;

        },
        [createFacture.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [createFacture.pending]: (state, action) => {
            state.loading = true;
            state.done = false;
        },
        [cancelFacture.fulfilled]: (state, action) => {
            if (action.payload) {
                if (action.payload.done) {
                    state.done = true;
                }
            }
            state.error = '';
            state.loading = false;

        },
        [cancelFacture.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [cancelFacture.pending]: (state, action) => {
            state.loading = true;
            state.done = false;
        },

        [listFacture.fulfilled]: (state, action) => {
            if (action.payload) {
                state.item_list = action.payload;
                state.item_list_src = action.payload;
            }
            state.error = '';
            state.loading = false;

        },
        [listFacture.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [listFacture.pending]: (state, action) => {
            state.loading = true;
            state.item_list = [];
            state.item_list_src = [];
        },
        [listDettes.fulfilled]: (state, action) => {
            if (action.payload) {
                state.item_list = action.payload;
                state.item_list_src = action.payload;
            }
            state.error = '';
            state.loading = false;

        },
        [listDettes.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [listDettes.pending]: (state, action) => {
            state.loading = true;
            state.item_list = [];
            state.item_list_src = [];
        },
        [listDettesByClient.fulfilled]: (state, action) => {
            if (action.payload) {
                state.client = action.payload;
            }
            state.error = '';
            state.loading = false;

        },
        [listDettesByClient.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [listDettesByClient.pending]: (state, action) => {
            state.loading = true;
            state.client = null;
        },
        [getFactureByCode.fulfilled]: (state, action) => {
            if (action.payload) {
                state.item = action.payload;
            }
            state.error = '';
            state.loading = false;

        },
        [getFactureByCode.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [getFactureByCode.pending]: (state, action) => {
            state.loading = true;
            state.item = null;
        },
    

    }
})

const { reducer } = billSlice;
export const { clear, doSelect, doFilter, checkColis , checkAllColis } = billSlice.actions;
export default reducer;