/* eslint-disable */
import React, { useState, useEffect } from 'react';
import MuList from '../../../ui/lists/MuClient.list';
import MuClientHeader from '../../../ui/headers/MuClient.header';
import MuDetails from '../../../ui/layouts/MuDetails';
import ConfirmModal from '../../../ui/modals/ConfirmModal';
import { clear, list, del, dispatchMu, doSelect , getClientHistory } from '../../../slices/Mu';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import UserStorage from '../../../services/UserStorage';
import { routes } from '../../../utils/helpers/routing.helper';


const MesColis = (props) => {

    const dispatch = useDispatch();
    const { loading, error, item_list, selected_item } = useSelector(state => state.mu);
    const [showDetails, setShowDetails] = useState(false);
    const history = useHistory()

    const [showDelModal, setShowDelModal] = useState(false);
    const hideDelMod = () => { setShowDelModal(false) }
    const doShowDelModal = () => { setShowDelModal(true) }
    const [mod_del_title, set_mod_del_title] = useState("");
    const [mod_del_msg, set_mod_del_msg] = useState("");

    const [showDispModal, setShowDispModal] = useState(false);
    const hideDispMod = () => { setShowDispModal(false) }
    const doShowDispModal = () => { setShowDispModal(true) }
    const [mod_disp_title, set_mod_disp_title] = useState("");
    const [mod_disp_msg, set_mod_disp_msg] = useState("");

    //Modal transfer confirm
    const [md_title_transfer, setMd_title_transfer] = useState("");
    const [md_msg_transfer, setMd_msg_transfer] = useState("");
    const [modalShow_transfer, setModalShow_transfer] = useState(false);
    const handleClose_transfer = () => setModalShow_transfer(false);


    const onItemDetailsClick = data => {
        dispatch(doSelect(data))
        setShowDetails(true)
    }

    const hideDetails = () => {
        setShowDetails(false)
    }
    // const onItemSelected = (data, etat) => {
    //     dispatch(checkColis({ data, etat }));

    // }

    const onPrint = () => {
        if (selected_item) {
            UserStorage.setParam(selected_item)
            const win = window.open(routes.mu_print, "_blank");
            win.focus();
        }
    }

    const onEdit = () => {
        if (selected_item)
            history.push(routes.mu_edit + selected_item.ref);
    }

    const onDelete = () => {
        if (selected_item) {
            set_mod_del_title("Supprimer unité mobile")
            set_mod_del_msg("Voulez-vous supprimer l'unité mobile' " + selected_item.ref + " ?")
            setShowDelModal(true);

        }
    }

    const doDelete = () => {
        if (selected_item) {
            console.log(selected_item)
            dispatch(del(selected_item.ref)).unwrap().then(() => {
                setShowDetails(false)
                setShowDelModal(false);
                dispatch(list());
            })
        }
    }

    const askDispatch = (data) => {
        if (data) {
            dispatch(doSelect(data));

            set_mod_disp_title("Dispatch unité mobile")
            set_mod_disp_msg("Voulez-vous dispatcher l'unité mobile' " + data?.ref + " ?")
            setShowDispModal(true);



        }
    }

    const onDispatch = () => {
        if (selected_item) {
            dispatch(dispatchMu(selected_item?.ref)).unwrap().then(d => {
                hideDispMod()
                setShowDetails(false)
                dispatch(list());
            })
        }

    }

    const onFilter = (filter) => {
        dispatch(getClientHistory({filter : filter }));
    }

    useEffect(() => {
        dispatch(getClientHistory());
        return () => {
            dispatch(clear());
        }
    }, [])

    return (
        <>
            <MuClientHeader lst_data={item_list} error={error} onFilter={onFilter}/>
            <MuList loading={loading} error={error} lst_data={item_list} onItemDetailsClick={onItemDetailsClick} onDispatch={askDispatch} />
            <MuDetails data={selected_item} isShow={showDetails} onHide={hideDetails} onPrint={onPrint} onEdit={onEdit} onDelete={onDelete} />
            <ConfirmModal onHide={hideDelMod} onOk={doDelete} title={mod_del_title} msg={mod_del_msg} show={showDelModal} loading={loading} />
            <ConfirmModal onHide={hideDispMod} onOk={onDispatch} title={mod_disp_title} msg={mod_disp_msg} show={showDispModal} loading={loading} />

        </>
    )
}

export default MesColis;



