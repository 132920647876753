/* eslint-disable */
import React, { useState, useEffect } from 'react';
import FactureClientList from '../../../ui/lists/FactureClient.list';
import FactureClientHeader from '../../../ui/headers/FactureClient.header';
import ConfirmModal from '../../../ui/modals/ConfirmModal';
import { clear, listExpedFacture} from '../../../slices/Facture';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import UserStorage from '../../../services/UserStorage';
import { routes } from '../../../utils/helpers/routing.helper';


const MesFacture = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { loading, error } = useSelector(state => state.bill);
    const[lst_facture, set_lst_facture] = useState([])

    const onItemDetailsClick = data => {
        history.push(routes.mes_bill_print+data.ref)
    }

    const onPrint = data => {
        if (data) {
            //UserStorage.setParam(data)
            const win = window.open(`/dashb/bill/mesbill/print/${data?.ref}`, "_blank");
            win.focus();
        }
    }

    const load_data = (filter) => {
        dispatch(listExpedFacture({filter : filter}))
          .unwrap()
          .then((res) => {
            set_lst_facture(Array.isArray(res) ? res : []);
          })
          .catch((err) => console.log(err));
      };

    useEffect(() => {
        load_data()
        return () => {
            dispatch(clear());
        }
    }, [])

    return (
        <>
            <FactureClientHeader lst_data={lst_facture} error={error} load_data={load_data}/>
            <FactureClientList loading={loading} error={error} lst_data={lst_facture} onItemDetailsClick={onItemDetailsClick} onPrint={onPrint} />
            {/* <ConfirmModal onHide={hideValidMod} onOk={doValid} title={mod_Valid_title} msg={mod_Valid_msg} show={showValidModal} loading={loading} /> */}

        </>
    )
}

export default MesFacture ;



