/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useReactToPrint } from 'react-to-print';
import UserStorage from '../../../services/UserStorage';
import Barcode from 'react-barcode';
import logo from '../../../assets/img/logo.png'

const PrintColis = (props) => {

    const [lst_colis, set_lst_colis] = useState(UserStorage.getParam());
    const history = useHistory()
    const componentRef = useRef();


    const barCodeOptions = {
        width: 2,
        height: 100,
        format: "EAN13",
        displayValue: true,
        fontOptions: "",
        font: "monospace",
        textAlign: "center",
        textPosition: "bottom",
        textMargin: 2,
        fontSize: 20,
        background: "#ffffff",
        lineColor: "#000000",
        margin: 10,
        marginTop: undefined,
        marginBottom: undefined,
        marginLeft: undefined,
        marginRight: undefined
    }

    // const onPrintClicked = data => {
    //     dispatch(selectColis(data))
    //     setShowDetails(true)
    // }
    const onPrintClicked = useReactToPrint({
        content: () => componentRef.current,
    });



    useEffect(() => {

        console.log(lst_colis)
        return () => {

        }
    }, [])

    return (
        <>
            {lst_colis &&
                <div className="col-md-12 bg-wt">
                    <div className="col-md-12 pt-2 pb-2 ps-4 pe-4">
                        <button className="btn btn-outline-success" onClick={onPrintClicked}>
                            <FontAwesomeIcon icon={['fas', 'print']} /> Imprimer bordereau d'expédition
                        </button>
                    </div>
                    {/* <ComponentToPrint ref={componentRef} /> */}
                    <div ref={componentRef} className="">
                        {lst_colis && Array.isArray(lst_colis) && lst_colis.map((data,index) => data &&
                            <div className="row mx-0 mb-3 p-0" style={{ pageBreakAfter: `${index % 2 == 0 ? 'avoid':'always'}`  }}>
                                <table className="no-padd table table-bordered brd-c-blk fnt-sm" width="99%" border="1" >
                                    <tr>
                                        <td width="50%" className="txt-ctr brd-r-blk">
                                            <img src={logo} height={80} />
                                        </td>
                                        <td width="50%" className="txt-ctr ">
                                            <Barcode value={data.code} width={2} height={50} style={{fontSize : "small"}}  />
                                            <div className='d-inline float-end'>
                                            
                                            <h5 className='px-3 py-1' style={{ display : 'inline' , border : '#000 1px solid'}}>{data?.afex_code_agence}</h5>
                                            <h5 className='px-3 py-1' style={{ display : 'inline' , border : '#000 1px solid'}}>{data?.afex_code_zone}</h5>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td  className="p-1 brd-r-blk"><b>Expediteur</b></td>
                                        <td  className="p-1"><b>Destinataire</b></td>
                                    </tr>
                                    <tr>
                                        <td className="p-1 brd-r-blk">
                                            <div style={{display : 'block', padding : '0px 8px'}}><b>Nom du l'expediteur :</b> {data.user && data.user.nom_com} </div>
                                            <div style={{display : 'block', padding : '0px 8px'}}><b>Tél du l'expediteur :</b> {data.user && data.user.tel} </div>
                                            <div style={{display : 'block', padding : '0px 8px'}}><b>Adresse :</b> {data.user && data.user.adresse}</div>
                                        </td>

                                        <td  className="p-1">
                                        <div style={{display : 'block', padding : '0px 8px'}}><b>Nom & Prenom :</b> {data.nom_cl} {data.prenom_cl}</div>
                                            {/* <b>Tél :</b> {data.tel_cl} <br /> */}
                                            <div style={{display : 'block', padding : '0px 8px'}}><b>Tél : </b>{data.tel_cl}</div>
                                            <div style={{display : 'block', padding : '0px 8px'}}><b>Adresse :</b> {data?.adresse} </div>
                                            <div style={{display : 'block', padding : '0px 8px'}}><b>Lieu : </b>{data.adresse_lieu}</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="p-1 brd-r-blk"><b>Infos Colis</b></td>
                                        <td className="p-1"><b>Signature Expediteur</b></td>
                                    </tr>

                                   

                                    <tr>
                                        <td  className="p-1 brd-r-blk">
                                        <div style={{display : 'block', padding : '0px 8px'}}><b>Marchandise:</b> {data?.marchandise} </div>
                                        <div style={{display : 'block', padding : '0px 8px'}}><b>Nombre de paquets:</b> {data?.nbr_paquets} </div>
                                        <div style={{display : 'block', padding : '0px 8px'}}><b>Observation : </b>{data?.note}</div>
                                        </td>
                                        <td  className="p-1" style={{height :100}}>
                                            
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="p-1 brd-r-blk"><b>Montant à payer</b></td>
                                        <td className="p-1"><b></b></td>
                                    </tr>

 
                                    <tr>
                                        <td  className="p-1 txt-ctr  brd-r-blk">
                                            <h5>{data.montant_ttc} TND</h5>
                                        </td>
                                        <td  className="p-1 text-end ">
                                        <h5 className='px-3 py-2' style={{ display : 'inline' , border : '#000 1px solid', textTransform : 'uppercase'}}>{data?.type_envoi}</h5>
                                            <h5 className='px-3' style={{ display : 'inline' , border : '#000 1px solid'}}>{data?.afex_code_agence_delivo}</h5>
                                            <h5 className='px-3' style={{ display : 'inline' , border : '#000 1px solid'}}>{data?.afex_code_zone_delivo}</h5>
                                        </td>
                                       
                                    </tr>
                                </table>
                            </div>
                        )}
                    </div>


                </div>

            }
        </>
    )
}

export default PrintColis;



